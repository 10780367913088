import React, { useState } from 'react';
import styled from 'styled-components';
import { Outlet, useLocation } from 'react-router-dom';

import { useAuth } from '../../features/AuthContext';
import AdminSidebar from '../AdminSidebar';

const LayoutContainer = styled.div`
    display: flex;
    background-color: #121212;
    font-family: Poppins;
    padding: 2rem;
    gap: 1rem;
    height: 100%;
    box-sizing: border-box;

    @media (max-width: 1024px) {
        flex-direction: column;
        padding: 1rem;
    }
`;

const ContentArea = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #1e1e1e;
    border-radius: 8px;
    padding: 2rem;
    box-sizing: border-box;

    @media (max-width: 1024px) {
        padding: 1rem;
        flex-direction: column;
         & > :first-child {
      padding: 0 !important;  /* Remove padding for the first child on mobile */ }
    }
`;

const HamburgerButton = styled.button`
    display: none;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #ffffff;
    cursor: pointer;
    padding: 0.5rem;
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 10;

    @media (max-width: 1024px) {
        display: block;
    }
`;

const SidebarWrapper = styled.div`
    @media (max-width: 1024px) {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 250px;
        background-color: #242424;
        padding: 1rem;
        border-radius: 8px;
        transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(-100%)')};
        transition: transform 0.3s ease;
        z-index: 9;
        box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    }
`;

const Copyright = styled.div`
  font-size: 0.9rem;
  color: #9F9B9B;
  margin-top: auto;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(90deg, #EC5F40, #999999);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
`;

const AdminLayout = () => {
    const { user } = useAuth();
    const currentLocation = useLocation();
    const showSidebar = user && user?.role?.name === 'Admin' && !currentLocation.pathname.includes('/course/');

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <LayoutContainer>
            {showSidebar && (
                <>
                    <HamburgerButton onClick={toggleSidebar}>
                        &#9776; {/* Unicode for the hamburger icon */}
                    </HamburgerButton>
                    <SidebarWrapper isOpen={isSidebarOpen}>
                        <AdminSidebar />
                    </SidebarWrapper>
                </>
            )}
            <ContentArea>
                <Outlet />
                <Copyright>© 2024 makesoftwares.com</Copyright>
            </ContentArea>
        </LayoutContainer>
    );
};

export default AdminLayout;