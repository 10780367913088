import React, { useRef, useEffect, useState } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

const VideoPlayer = ({ autoplay, onEnd, onReady, src }) => {
    const videoRef = useRef(null);
    const playerRef = useRef(null);

    useEffect(() => {
        // Cleanup function to dispose of the player if it exists
        return () => {
            if (playerRef.current) {
                playerRef.current.dispose();
                playerRef.current = null;
            }
        };
    }, []);

    useEffect(() => {
        if (playerRef.current) {
            playerRef.current.dispose();
            playerRef.current = null;
        }
        if (!playerRef.current) {
            const videoElement = document.createElement('video-js');
            videoElement.classList.add('vjs-big-play-centered');
            videoRef.current.appendChild(videoElement);

            const videoJsOptions = {
                autoplay,
                controls: true,
                responsive: true,
                preload: 'auto',
                fluid: true,
            };

            const player = (playerRef.current = videojs(videoElement, videoJsOptions, () => {
                videojs.log('Player is ready');
                onReady && onReady(player);
                onEnd && player.on('ended', () => { onEnd(); });
            }));
            player.src({ src, type: 'video/mp4' });
        }
    }, [autoplay, src]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
            <div data-vjs-player style={{ width: '100%' }}>
                <div ref={videoRef} className="video-js" style={{ width: '100%', height: '100%' }} />
            </div>
        </div>
    );
};

export default VideoPlayer;
