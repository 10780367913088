import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Button = styled.button`
    cursor: pointer;
    width: fit-content;
    padding: 0.8rem;
    font-size: 0.8rem;
    font-weight: 600;
    color: white;
    background: #EC5F40;
    border: 0px;
    border-radius: 8px;

    &:hover {
        background: #DA5A50;
    }
`;

export const AddEditBtn = styled(Link)`
    display: block;
    text-decoration: none;
    cursor: pointer;
    width: fit-content;
    padding: 0.8rem;
    font-size: 0.8rem;
    font-weight: 600;
    color: white;
    background: #EC5F40;
    border: 0px;
    border-radius: 8px;

    &:hover {
        background: #DA5A50;
    }
`;