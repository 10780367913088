import { useEffect } from "react";
import { useAuth } from "../features/AuthContext";
import { useNavigate } from "react-router-dom";

const Logout = () => {

    const { signOut } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const handleSignout = async () => {
            signOut();
            navigate('/login');
        }
        handleSignout();
    }, [])
}

export default Logout;