import React, { useState } from 'react';
import styled from 'styled-components';
import { Container } from '../components/common/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { resetPassword, verifyCode } from '../api/users';
import PasswordInput from '../components/common/PasswordInput';
import { toast } from 'sonner';
import { CenterContainer, NextButton, CodeContainer, CodeInput } from '../components/common/styledComponents/verifyCodeStyle'

const VerifyCode = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [code, setCode] = useState(['', '', '', '']); // State to store the 4 digits of the code
    const [isValidCode, setIsValidCode] = useState(false);

    const handleCodeChange = (index, event) => {
        const value = event.target.value;
        if (/[^0-9]/.test(value)) return; // Allow only numeric input

        const newCode = [...code];
        newCode[index] = value;

        // Focus on the next input if there's a value
        if (value && index < 3) {
            document.getElementById(`code-input-${index + 1}`).focus();
        }
        setCode(newCode);
    };

    const handleVerification = async (event) => {
        event.preventDefault();
        const verificationCode = code.join('');
        const data = {
            code: verificationCode
        }
        const result = await verifyCode(data)
        if (result.status === 200) {
            setIsValidCode(true);
        }
    };

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        const data = {
            id: id,
            password: e.target.password.value,
        }
        const result = await resetPassword(data)
        if (result.status === 200) {
            navigate('/login')
            toast.success('Password reset successful!');
        }
    }

    return (
        <Container>
            <CenterContainer>
                {isValidCode ? (
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <h1 style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap', margin: 0, fontWeight: 600, fontSize: '3rem', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
                            ENTER NEW PASSWORD
                        </h1>
                        <form style={{ width: '100%', textAlign: 'center' }} onSubmit={handlePasswordReset}>
                            <PasswordInput name="password" type="password" placeholder="********" required />
                            <NextButton type="submit">Next</NextButton>
                        </form>
                    </div>
                ) : (
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <h1 style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap', margin: 0, fontWeight: 600, fontSize: '3rem', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
                            VERIFY CODE
                        </h1>
                        <p style={{ color: '#9F9B9B', margin: 0, fontSize: '1rem', textAlign: 'center' }}>
                            Enter the verification code sent to your email
                        </p>
                        <form style={{ width: '100%', textAlign: 'center', marginTop: '1rem' }} onSubmit={handleVerification}>
                            <CodeContainer>
                                {code.map((digit, index) => (
                                    <CodeInput
                                        key={index}
                                        id={`code-input-${index}`}
                                        type="text"
                                        value={digit}
                                        maxLength="1"
                                        onChange={(e) => handleCodeChange(index, e)}
                                        onFocus={(e) => e.target.select()} // Select input value on focus
                                    />
                                ))}
                            </CodeContainer>
                            <NextButton type="submit">Next</NextButton>
                        </form>
                    </div>
                )}
            </CenterContainer>
        </Container>
    );
};

export default VerifyCode;
