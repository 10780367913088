import React, { useEffect, useState } from 'react';
import Table from '../../components/Table';
import API from '../../api/axios';
import { EmailLink, ProgressBarContainer, ProgressBarFill, ProgressLabel } from '../../components/common/styledComponents/usersStyles';


const Users = () => {
    const [users, setUsers] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [activeSubchaptersNumber, setActiveSubchaptersNumber] = useState(0);
    const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'asc' });

    useEffect(() => {
        fetchUsers();
    }, [currentPage, searchQuery, sortConfig]);

    const fetchUsers = async () => {
        try {
            const result = await API.get(`/users`, {
                params: {
                    page: currentPage,
                    search: searchQuery,
                    sortBy: sortConfig.key,
                    sortDirection: sortConfig.direction
                }
            });
            setUsers(result.data.users);
            setActiveSubchaptersNumber(result.data.activeSubchaptersNumber)
            setTotalPages(result.data.totalPages);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1); // Reset to the first page on search
    };

    const handleSortChange = (key) => {
        setSortConfig((prev) => ({
            key,
            direction: prev.key === key ? (prev.direction === 'asc' ? 'desc' : 'asc') : 'asc'
        }));
    };

    const calculateProgressPercentage = (userProgress) => {
        const completedSubChapters = userProgress.filter(progress => progress.completed).length;
        return activeSubchaptersNumber > 0 ? Math.round((completedSubChapters / activeSubchaptersNumber) * 100) : 0;
    };

    const columns = [
        {
            header: 'First Name',
            accessor: 'firstName',
            sortable: true,
            Cell: (cellData) => cellData.firstName
        },
        {
            header: 'Last Name',
            accessor: 'lastName',
            sortable: true,
            Cell: (cellData) => cellData.lastName
        },
        {
            header: 'Email',
            accessor: 'email',
            sortable: true,
            Cell: (cellData) => (
                <EmailLink href={`mailto:${cellData.email}`}>{cellData.email}</EmailLink>
            )
        },
        {
            header: 'Role',
            accessor: 'role.name',
            sortable: true,
            Cell: (cellData) => cellData.role?.name || 'N/A'
        },
        {
            header: 'User Progress',
            accessor: 'userProgress',
            Cell: (cellData) => {
                const progressPercentage = calculateProgressPercentage(cellData.userProgress || []);
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ProgressBarContainer>
                            <ProgressBarFill percentage={progressPercentage} />
                        </ProgressBarContainer>
                        <ProgressLabel>{`${progressPercentage}%`}</ProgressLabel>
                    </div>
                );
            }
        },
        // {
        //     header: 'Actions',
        //     accessor: 'id',
        //     sortable: false,
        //     Cell: (cellData) => (
        //         <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
        //             <AddEditBtn to={`/users/add-edit/${cellData.id}`}>Edit</AddEditBtn>
        //             <Button onClick={() => handleDelete(cellData.id)}>Delete</Button>
        //         </div>
        //     )
        // }
    ];

    return (
        <div style={{ padding: '2rem' }}>
            <h1 style={{ color: 'white' }}>Users</h1>
            <Table
                columns={columns}
                data={users}
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                searchQuery={searchQuery}
                onSearchChange={handleSearchChange}
                onSortChange={handleSortChange}
                sortConfig={sortConfig}
            />
        </div>
    );
};

export default Users;
