import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Button = styled.button`
    cursor: pointer;
    width: fit-content;
    padding: 0.8rem;
    font-size: 0.8rem;
    font-weight: 600;
    color: white;
    background: #EC5F40;
    border: 0px;
    border-radius: 8px;

    &:hover {
        background: #DA5A50;
    }
`;

export const AddEditBtn = styled(Link)`
    display: block;
    text-decoration: none;
    cursor: pointer;
    width: fit-content;
    padding: 0.8rem;
    font-size: 0.8rem;
    font-weight: 600;
    color: white;
    background: #EC5F40;
    border: 0px;
    border-radius: 8px;

    &:hover {
        background: #DA5A50;
    }
`;

export const EmailLink = styled.a`
    color: inherit;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

export const ProgressBarContainer = styled.div`
    width: 100%;
    background-color: #e0e0df;
    border-radius: 8px;
    overflow: hidden;
`;

export const ProgressBarFill = styled.div`
    height: 16px;
    background-color: #EC5F40; /* Orange color consistent with the site theme */
    width: ${(props) => props.percentage}%;
    transition: width 0.3s ease-in-out;
`;

export const ProgressLabel = styled.span`
    margin-left: 8px;
    font-size: 0.8rem;
    font-weight: 600;
    color: white;
`;