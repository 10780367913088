import React from 'react';
import { FaSearch } from 'react-icons/fa';
import styled from 'styled-components';

// Styled components
const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: 1024px) {
    overflow-x: auto; /* Allows horizontal scrolling on small screens */
  }
`;

const SearchInput = styled.input`
  padding-left: 2.5rem!important;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #2c2c2c;
  color: #fff;
  width: 100%;
  max-width: 300px;
  font-size: 1rem;

  &::placeholder {
    color: #888;
  }

  &:focus {
    border-color: #EC5F40;
    outline: none;
  }

  @media (max-width: 1024px) {
    max-width: 260px;
  }
`;

const TableElement = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: #1e1e1e;
  color: #fff;
  border-radius: 8px;
  overflow: hidden;

  @media (max-width: 1024px) {
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
`;

const TableHeader = styled.th`
  padding: 1rem;
  text-align: left;
  background-color: #EC5F40;
  color: #fff;
  border-bottom: 1px solid #444;
  cursor: ${({ sortable }) => (sortable ? 'pointer' : 'default')};
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: #d94c35;
  }

  @media (max-width: 1024px) {
    padding: 0.75rem;
  }

  @media (max-width: 480px) {
    padding: 0.5rem;
  }
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #444;

  &:nth-child(even) {
    background-color: #2a2a2a;
  }

  @media (max-width: 1024px) {
    padding: 0.5rem;
  }
`;

const TableCell = styled.td`
  padding: 1rem;

  @media (max-width: 1024px) {
    padding: 0.75rem;
  }

  @media (max-width: 480px) {
    padding: 0.5rem;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 0.5rem;
  }
`;

const PageButton = styled.button`
  padding: 0.5rem 0.8rem;
  border: 1px solid #444;
  margin-right: 0.2rem;
  border-radius: 8px;
  background-color: ${({ active }) => (active ? '#EC5F40' : '#333')};
  color: ${({ active }) => (active ? '#fff' : '#ccc')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  &:hover {
    background-color: ${({ disabled }) => (disabled ? '#333' : '#EC5F40')};
  }

  @media (max-width: 1024px) {
    padding: 0.4rem 0.6rem;
  }

  @media (max-width: 480px) {
    padding: 0.3rem 0.5rem;
  }
`;

// Table component
const Table = ({
  columns,
  data,
  totalPages,
  currentPage,
  onPageChange,
  searchQuery,
  onSearchChange,
  onSortChange,
  sortConfig
}) => {

  const renderPageNumbers = () => {
    const maxPagesToShow = 5;
    const half = Math.floor(maxPagesToShow / 2);

    let startPage = Math.max(currentPage - half, 1);
    let endPage = Math.min(currentPage + half, totalPages);

    if (endPage - startPage < maxPagesToShow - 1) {
      startPage = Math.max(endPage - maxPagesToShow + 1, 1);
      endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);
    }

    return [
      startPage > 1 && (
        <React.Fragment key={1}>
          <PageButton
            onClick={() => onPageChange(1)}
            disabled={currentPage === 1}
          >
            1
          </PageButton>
          {startPage > 2 && <PageButton disabled>...</PageButton>}
        </React.Fragment>
      ),
      ...Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map(i => (
        <PageButton
          key={i}
          onClick={() => onPageChange(i)}
          active={i === currentPage}
        >
          {i}
        </PageButton>
      )),
      endPage < totalPages && (
        <React.Fragment key={totalPages}>
          {endPage < totalPages - 1 && <PageButton disabled>...</PageButton>}
          <PageButton
            onClick={() => onPageChange(totalPages)}
            disabled={currentPage === totalPages}
          >
            {totalPages}
          </PageButton>
        </React.Fragment>
      )
    ];
  };

  return (
    <TableContainer>
      <div style={{ position: 'relative' }}>
        <FaSearch style={{ position: 'absolute', left: '0.75rem', top: '50%', transform: 'translateY(-50%)', color: '#888' }} />
        <SearchInput
          type='text'
          placeholder='Search...'
          value={searchQuery}
          onChange={onSearchChange}
        />
      </div>
      <TableElement>
        <thead>
          <tr>
            {columns.map((col, index) => (
              <TableHeader
                key={index}
                sortable={col.sortable}
                onClick={() => col.sortable && onSortChange(col.accessor)}
              >
                {col.header}
                {sortConfig.key === col.accessor && (
                  <span style={{ marginLeft: '0.5rem' }}>
                    {sortConfig.direction === 'asc' ? '▲' : '▼'}
                  </span>
                )}
              </TableHeader>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {columns.map((col, colIndex) => (
                  <TableCell key={colIndex}>
                    {col.Cell ? col.Cell(row) : row[col.accessor]}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <tr>
              <TableCell colSpan={columns.length} style={{ textAlign: 'center' }}>No data available</TableCell>
            </tr>
          )}
        </tbody>
      </TableElement>
      <PaginationContainer>
        <PageButton
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          &lt; Previous
        </PageButton>
        <div>{renderPageNumbers()}</div>
        <PageButton
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next &gt;
        </PageButton>
      </PaginationContainer>
    </TableContainer>
  );
};

export default Table;
