import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import API from '../api/axios';
import { AskButton, Container } from '../components/common/styles';
import styled from 'styled-components';

import CircleProgress from '../components/common/CircleProgress';
import 'video.js/dist/video-js.css';
import VideoPlayer from './VideoPlayer';
import QAList from '../components/QuestionsAndAnswers';
import Switch from '../components/common/Switch';
import { useAuth } from '../features/AuthContext';

import CourseSidebar from '../components/CourseSidebar';
import { GiHamburgerMenu } from 'react-icons/gi';
import SocialMediaButtons from '../components/SocialMediaButtons';
import { toast } from 'sonner';
import { FaDownload, FaShare } from 'react-icons/fa';
import { useSettings } from '../providers/SettingsProvider';
import { QAContainer, ContentWrapper, Header, SidebarWrapper, Button, MainActionButtonsWrapper } from '../components/common/styledComponents/courseStyles'

import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import ShareSocial from '../components/common/ShareSocial';


const Drawer = styled.div`
    top: 0;
    bottom:0;
    position:absolute;
    overflow-y:scroll;
    overflow-x:hidden;
    height: 100%; /* Full viewport height */
    // width: 100%; /* Full viewport width */
    background: #000; /* Black background */
    color: #fff; /* Text color for contrast */
    border-radius: 8px;
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    @media (max-width: 1024px) {
        transform: ${({ isOpen }) => isOpen ? 'translateY(0)' : 'translateY(-100%)'};
    }
`;

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    z-index: 999;
    display: ${({ isOpen }) => isOpen ? 'block' : 'none'};
`;

const HamburgerButton = styled.button`
    top: 1rem;
    left: 1rem;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1001;
    opacity: 0;
    visibility: hidden;
    display: none;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out; /* Smooth transition */
    
    @media (max-width: 1024px) {
        display: block;
        opacity: ${({ drawerOpen }) => drawerOpen ? '0' : '1'};
        visibility: ${({ drawerOpen }) => drawerOpen ? 'hidden' : 'visible'};
    }
`;

const CustomWord = styled.span`
    background: linear-gradient(90.93deg, #EC5F40 0.8%, #999999 63.15%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display:block;
`

const NextButton = styled.button`
    cursor: pointer;
    background: inherit;
    color: white;
    padding: 0.5rem 2rem 0.5rem 2rem;
    border:1px solid #EC5F40;
    border-radius: 8px;
    margin-top: 1rem;
`

const Course = () => {
    const { id } = useParams();
    const { user } = useAuth();
    const [autoplay, setAutoPlay] = useState(false);
    const [course, setCourse] = useState(null);
    const [selectedSubchapter, setSelectedSubchapter] = useState(null);
    const [progressPercentage, setProgressPercentage] = useState(0);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [isManualProgressionOn, setIsManualProgressionOn] = useState(false);
    const [isAutoplayEnabled, setIsAutoplayEnabled] = useState(false);
    const [courseIsFinished, setCourseIsFinished] = useState(false);
    const [isOpenSocialMediaShare, setIsOpenSocialMediaShare] = useState(false);

    const autoplayRef = useRef(autoplay);
    const navigate = useNavigate();
    const { settings } = useSettings();

    useEffect(() => {
        autoplayRef.current = autoplay;  // Update the ref when autoplay changes
    }, [autoplay]);

    useEffect(() => {
        const fetchCourseAndProgress = async () => {
            try {
                const courseResponse = await API.get(`/courses/${id}`);
                const userProgressResponse = await API.get(`/user-progress/${id}/${user.id}`);

                // Check if userProgressData is an array
                if (!Array.isArray(userProgressResponse.data)) {
                    throw new TypeError('User progress data is not an array');
                }

                const userProgressData = userProgressResponse.data;

                // Create a map of subChapterId to completed status for easy lookup
                const progressMap = userProgressData.reduce((acc, { subChapterId, completed }) => {
                    acc[subChapterId] = completed;
                    return acc;
                }, {});

                // Calculate the completed count for each chapter
                const updatedChapters = courseResponse.data.chapters.map(chapter => {
                    const completedCount = chapter.subchapters.filter(subchapter => progressMap[subchapter.id]).length;
                    return {
                        ...chapter,
                        completedCount, // Add completedCount to each chapter
                        subchapters: chapter.subchapters.map(subchapter => ({
                            ...subchapter,
                            completed: progressMap[subchapter.id] || false,
                        }))
                    };
                });

                // Calculate total completion percentage
                const totalSubchapters = updatedChapters.flatMap(chapter => chapter.subchapters).length;
                const totalCompleted = updatedChapters.flatMap(chapter => chapter.subchapters).filter(subchapter => subchapter.completed).length;
                const newPercentage = parseInt(totalSubchapters > 0 ? (totalCompleted / totalSubchapters) * 100 : 0);

                setCourse({
                    ...courseResponse.data,
                    chapters: updatedChapters
                });

                // Determine the next subchapter to select
                const allSubchapters = updatedChapters.flatMap(chapter => chapter.subchapters);

                let nextSubchapter = null;

                if (userProgressData.length > 0) {
                    const lastCompletedIndex = allSubchapters.findIndex(subchapter => subchapter.completed === false && allSubchapters.slice(0, allSubchapters.indexOf(subchapter)).every(sc => sc.completed));
                    if (lastCompletedIndex !== -1) {
                        nextSubchapter = allSubchapters[lastCompletedIndex] || null;
                    }
                }

                if (!nextSubchapter) {
                    nextSubchapter = allSubchapters[0];
                }

                setSelectedSubchapter(nextSubchapter);
                setProgressPercentage(newPercentage);

            } catch (error) {
                console.error('Error fetching course and progress:', error);
            }
        };
        if (settings) {
            const manualProgression = settings.find(s => s.key === 'enable_auto_course_progression');
            if (manualProgression && manualProgression.value === 'true') {
                setIsManualProgressionOn(true);
            }
            const autoplaySetting = settings.find(s => s.key === 'enable_autoplay');
            if (autoplaySetting && autoplaySetting.value === 'true') {
                setIsAutoplayEnabled(true);
            }
        }
        fetchCourseAndProgress();
    }, [id, user.id, settings]);

    const nextChapter = async () => {
        // Flatten all subchapters from the chapters
        const allSubchapters = course.chapters.flatMap(chapter => chapter.subchapters);

        // Find the index of the current subchapter
        const currentIndex = allSubchapters.findIndex(sc => sc.id === selectedSubchapter.id);

        // Check if currentIndex is valid
        if (currentIndex === -1) {
            console.warn('Current subchapter not found.');
            return;
        }

        const nextIndex = currentIndex + 1;

        // Handle the current subchapter completion
        const currentSubchapter = allSubchapters[currentIndex];
        if (currentSubchapter && !currentSubchapter.completed) {
            // Update completion status
            await handleCheckboxClick(new Event('click', { bubbles: true }), selectedSubchapter.id);
        }

        // Navigate to the next subchapter if available
        if (nextIndex < allSubchapters.length) {
            const nextSubchapter = allSubchapters[nextIndex];
            setSelectedSubchapter(nextSubchapter);
        } else {
            setAutoPlay(false);
            setSelectedSubchapter(allSubchapters[0]);
            console.log('No more subchapters available.');
        }
    }

    const finishCourse = async () => {
        setCourseIsFinished(prevValue => !prevValue);
    }

    const handleNextClick = async (type) => {
        if (type == 'next') {
            await nextChapter();
        } else {
            await finishCourse();
            await nextChapter();
        }
    }

    const hasRemainingSubchapters = () => {
        if (!course || !selectedSubchapter) return false;

        // Flatten all subchapters from the chapters
        const allSubchapters = course.chapters.flatMap(chapter => chapter.subchapters);

        // Find the index of the current subchapter
        const currentIndex = allSubchapters.findIndex(sc => sc.id === selectedSubchapter.id);

        if (currentIndex === -1) return false; // Current subchapter not found

        // Check if there are any remaining subchapters after the current one
        return currentIndex < allSubchapters.length - 1;
    };

    const handleSubchapterClick = async (subchapterId) => {
        // Flatten all subchapters from the chapters
        const allSubchapters = course.chapters.flatMap(chapter => chapter.subchapters);
        const subchapter = allSubchapters.find(sc => sc.id === subchapterId);

        if (!subchapter) {
            console.warn('Subchapter not found.');
            return;
        }

        // Find the index of the selected subchapter
        const currentIndex = allSubchapters.findIndex(sc => sc.id === subchapterId);

        if (currentIndex === -1) {
            console.warn('Subchapter index not found.');
            return;
        }

        // Check if the user can access this subchapter
        const previousSubchapters = allSubchapters.slice(0, currentIndex);
        const nextUncompletedSubchapter = allSubchapters.slice(currentIndex + 1).find(sc => !sc.completed);

        // Check if user has completed all previous subchapters or if this is a previously completed chapter
        const canAccess = isManualProgressionOn ? true : previousSubchapters.every(sc => sc.completed) || subchapter.completed || (nextUncompletedSubchapter && nextUncompletedSubchapter.id === subchapterId);

        if (!canAccess) {
            toast.warning('Please complete the previous chapters before attempting to access this one!')
            console.warn('Access denied: You cannot access this chapter.');
            return;
        }

        // Close the drawer if it's open
        setDrawerOpen(false);
        setSelectedSubchapter(subchapter);
    };

    const handleCheckboxClick = async (e, subchapterId) => {
        e.stopPropagation();

        // Toggle completion status
        const updatedChapters = course.chapters.map(chapter => {
            const updatedSubchapters = chapter.subchapters.map(subchapter => {
                if (subchapter.id === subchapterId) {
                    return {
                        ...subchapter,
                        completed: !subchapter.completed
                    };
                }
                return subchapter;
            });

            // Recalculate completedCount
            const completedCount = updatedSubchapters.filter(subchapter => subchapter.completed).length;


            return {
                ...chapter,
                subchapters: updatedSubchapters,
                completedCount // Update the completed count
            };
        });

        const totalSubchapters = updatedChapters.flatMap(chapter => chapter.subchapters).length;
        const totalCompleted = updatedChapters.flatMap(chapter => chapter.subchapters).filter(subchapter => subchapter.completed).length;
        const newPercentage = parseInt(totalSubchapters > 0 ? (totalCompleted / totalSubchapters) * 100 : 0);

        setCourse({
            ...course,
            chapters: updatedChapters
        });
        setProgressPercentage(newPercentage);

        try {
            // Update user progress
            await API.post('user-progress/update-user-progress', {
                userId: user.id,
                courseId: id,
                subChapterId: subchapterId,
                completed: !course.chapters.flatMap(chapter => chapter.subchapters).find(sc => sc.id === subchapterId).completed
            });

        } catch (error) {
            console.error('Error updating user progress:', error);
        }
    };

    const onVideoEnd = async () => {
        if (autoplayRef.current && selectedSubchapter.file?.type?.includes('video')) {
            try {
                await nextChapter();
            } catch (error) {
                console.error('Error handling video end:', error);
            }
        }
    };

    const handleFileDownload = (filePath) => {
        const url = `${process.env.REACT_APP_AWS_CLOUDFRONT_DIST}/${filePath}`;
        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.download = true; // Ensure the browser treats it as a download
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
    };

    if (!course) {
        return (<div>Loading...</div>);
    }

    return (
        <Container style={{ postion: 'relative' }}>
            <div style={{ position: 'absolute', zIndex: '9999', overflow: 'auto', height: '100%', width: '100%', zIndex: drawerOpen ? '2' : '-9999' }}>
                <Overlay isOpen={drawerOpen} onClick={() => setDrawerOpen(false)} />
                <Drawer isOpen={drawerOpen}>
                    <Button onClick={() => navigate('/logout')} style={{ marginBottom: '1rem', textDecoration: 'none', color: 'white', width: 'fit-content' }}>Logout</Button>
                    <Button onClick={() => navigate('/courses')} style={{ marginBottom: '1rem', textDecoration: 'none', color: 'white', width: 'fit-content' }}>Dashboard</Button>
                    <CourseSidebar
                        course={course}
                        selectedSubchapter={selectedSubchapter}
                        handleSubchapterClick={handleSubchapterClick}
                    />
                </Drawer>
            </div>
            <ContentWrapper style={{ padding: '2rem' }}>
                <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                    <Header>
                        <HamburgerButton drawerOpen={drawerOpen} onClick={() => setDrawerOpen(prev => !prev)}>
                            <GiHamburgerMenu size={24} color='#fff' />
                        </HamburgerButton>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem' }}>
                            <h2>{course.title}</h2>
                            <CircleProgress percentage={progressPercentage} size={60} strokeWidth={4} />
                        </div>
                        {isAutoplayEnabled && (
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '0.25rem', alignItems: 'center' }}>
                                Autoplay
                                <Switch checked={autoplay} onChange={() => setAutoPlay(prev => !prev)} />
                            </div>
                        )}
                    </Header>
                    <MainActionButtonsWrapper>
                        {user && user.role.name == 'Admin' && (
                            <Button onClick={() => navigate('/courses')} style={{ marginBottom: '0' }}>Admin Dashboard</Button>
                        )}
                        <Button onClick={() => navigate('/logout')} style={{ marginBottom: '0' }}>Logout</Button>
                    </MainActionButtonsWrapper>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                    <SidebarWrapper style={{ marginRight: '1rem' }}>
                        <CourseSidebar
                            course={course}
                            selectedSubchapter={selectedSubchapter}
                            handleSubchapterClick={handleSubchapterClick}
                        />
                    </SidebarWrapper>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        {courseIsFinished ? (
                            <>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '80svh' }}>
                                    <h1 style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap', margin: 0, fontWeight: 600, fontSize: '3rem', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
                                        <CustomWord>
                                            SUCCESSFULLY
                                        </CustomWord>
                                        <span style={{ display: 'block' }}>
                                            FINISHED COURSE
                                        </span>
                                    </h1>
                                    <p style={{ color: '#9F9B9B', margin: 0, fontSize: '1rem', textAlign: 'center' }}>
                                        Congratulation! You have finished the course!
                                    </p>
                                    <NextButton onClick={() => setCourseIsFinished(false)}>Return</NextButton>
                                </div>
                            </>
                        ) : selectedSubchapter ? (
                            <>
                                {selectedSubchapter.file?.type?.includes('pdf') ? (
                                    <>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                            <SocialMediaButtons />
                                            <Button onClick={() => setIsOpenSocialMediaShare(true)}>Share &nbsp;<FaShare /></Button>
                                            <ShareSocial
                                                isOpen={isOpenSocialMediaShare}
                                                onClose={() => setIsOpenSocialMediaShare(false)}
                                                url={`${process.env.REACT_APP_URL}/login`}
                                                socialTypes={['facebook', 'twitter', 'reddit', 'linkedin', 'whatsapp']}
                                            />
                                            <span style={{ fontSize: '2.125rem', fontWeight: 'bold ' }}>{selectedSubchapter?.title ?? ''}</span>
                                            <span style={{ fontSize: '1rem' }}>
                                                <Markdown rehypePlugins={[rehypeRaw]}>
                                                    {selectedSubchapter?.description ?? ''}
                                                </Markdown>
                                            </span>
                                        </div>
                                    </>
                                ) : selectedSubchapter.file?.type?.includes('video') && autoplayRef ? (
                                    <>
                                        <VideoPlayer
                                            onEnd={onVideoEnd}
                                            autoplay={autoplayRef.current}
                                            src={`${process.env.REACT_APP_AWS_CLOUDFRONT_DIST}/${selectedSubchapter.file.path}`}
                                            onReady={() => console.log('Player ready')}
                                        />
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                            <SocialMediaButtons />
                                            <Button onClick={() => setIsOpenSocialMediaShare(true)}>Share &nbsp;<FaShare /></Button>
                                            <ShareSocial
                                                isOpen={isOpenSocialMediaShare}
                                                onClose={() => setIsOpenSocialMediaShare(false)}
                                                url={`${process.env.REACT_APP_URL}/login`}
                                                socialTypes={['facebook', 'twitter', 'reddit', 'linkedin', 'whatsapp']}
                                            />
                                            <span style={{ fontSize: '2.125rem', fontWeight: 'bold ', marginTop: '1rem' }}>{selectedSubchapter?.title ?? ''}</span>
                                            <span style={{ fontSize: '1rem' }}>
                                                <Markdown rehypePlugins={[rehypeRaw]}>
                                                    {selectedSubchapter?.description ?? ''}
                                                </Markdown>
                                            </span>
                                        </div>
                                    </>
                                ) : null}
                                {!selectedSubchapter.file?.type?.includes('video') && (
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem', marginTop: '1rem' }}>
                                        <Button onClick={() => handleFileDownload(selectedSubchapter.file.path)} style={{ background: '#f0ad4e', display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center', gap: '1rem', marginBottom: '0' }}>
                                            <FaDownload size={20} />
                                        </Button>
                                        <span>{selectedSubchapter.file.name}</span>
                                    </div>
                                )}
                                <QAContainer>
                                    <QAList subchapter={selectedSubchapter} nextButton={hasRemainingSubchapters} handleNextClick={handleNextClick} />
                                </QAContainer>
                            </>
                        ) : ''}
                    </div>
                </div>
            </ContentWrapper>
        </Container >
    );
};

export default Course;
