import { useEffect } from "react";
import styled from "styled-components";
import { Container } from "../components/common/styles";
import { useAuth } from '../features/AuthContext';
import { useNavigate } from "react-router-dom";
import Input from "../components/common/Input";
import { toast } from "sonner";
import PasswordInput from "../components/common/PasswordInput";
import API from "../api/axios";

import { Card, CardHeader, CardBody, CardFooter, Button, Copyright } from '../components/common/styledComponents/loginStyles';

const CenterContainer = styled.div`
  font-family: Poppins;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

const Login = () => {

    const { signIn } = useAuth();

    const navigate = useNavigate();

    useEffect(() => {
        const verifyUser = async () => {
            const result = API.post('/auth/verify-token').then(async (result) => {
                if (result && result.status == 200) {
                    const activeCourseId = await getActiveCourseId();
                    navigate(`/course/${activeCourseId}`)
                } else {
                    return;
                }
            }).catch((err) => {
                console.error(err);
            });
        }
        verifyUser();
    }, []);

    const getActiveCourseId = async () => {
        try {
            const response = await API.get('/courses/active-course');
            if (response.status === 200) {
                return response.data;
            } else {
                throw new Error("Failed to fetch active course: " + (response.data || "Please try again!"));
            }
        } catch (error) {
            throw new Error("An error occurred while fetching the active course: " + error.message);
        }
    };


    async function handleSubmit(event) {
        event.preventDefault();

        // Create a promise for the sign-in process
        const signInPromise = new Promise(async (resolve, reject) => {
            try {
                const result = await signIn(event.target.email.value, event.target.password.value);
                if (result.status === 200) {
                    try {
                        const activeCourseId = await getActiveCourseId();
                        result.data.courseId = activeCourseId;
                        resolve(result);
                    } catch (error) {
                        reject(error);
                    }
                } else {
                    reject(new Error(result.data ?? result.data + ": Please try again!"));
                }
            } catch (error) {
                reject(error);
            }
        });


        // Use toast.promise to handle the promise with loading, success, and error states
        toast.promise(signInPromise, {
            loading: 'Signing in...',
            success: (result) => {
                navigate(`/course/${result.data.courseId}`)
                return `Welcome ${result.data.firstName} ${result.data.lastName}!`;
            },
            error: (error) => {
                return `Sign in failed: ${error.message}`;
            },
        });
    }

    return (
        <Container>
            <CenterContainer>
                <Card>
                    <CardHeader>
                        Login to your account
                    </CardHeader>
                    <CardBody>
                        <form onSubmit={handleSubmit}>
                            <Input name="email" type="email" placeholder="user@example.com" label={"Email"} required />
                            <PasswordInput name="password" type="password" placeholder="********" label={"Password"} includeForgot required></PasswordInput>
                            <Button type="submit" >Login now</Button>
                        </form>
                        {/* <GoogleButton onClick={() => alert('test')}>
                            <FcGoogle />
                            <span>Continue with Google</span>
                        </GoogleButton>
                         */}
                        <span style={{ textAlign: 'center', width: '100%', display: 'block', marginTop: '1.5rem', color: '#9F9B9B' }}>Don't have an account? <a href="/register" style={{ color: '#EC5F4099', textDecoration: 'none' }}>Sign up</a></span>
                    </CardBody>
                    <CardFooter>
                        <Copyright>© 2024 makesoftwares.com</Copyright>
                    </CardFooter>
                </Card>
            </CenterContainer>
        </Container>
    )
}

export default Login;