import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import ProtectedRoute from './features/ProtectedRoute';
import { AuthProvider } from './features/AuthContext';
import Layout from './components/Layout';
import Register from './pages/Register';
import { Toaster } from 'sonner';
import ForgotPassword from './pages/ForgotPassword';
import VerifyCode from './pages/VerifyCode';
import Course from './pages/Course';
import ProtectedAdminRoute from './features/ProtectedAdminRoute';
import Logout from './pages/Logout';
import Courses from './pages/Admin/Courses';
import Users from './pages/Admin/Users';
import AddEditCourse from './pages/Admin/AddEditCourse';
import ControlPanel from './pages/Admin/ControlPanel';
import AdminLayout from './components/common/AdminLayout';
import { SettingsProvider } from './providers/SettingsProvider';
import HeadMetadata from './components/common/HeadMetadata';

function App() {
  return (
    <BrowserRouter>
      <Toaster richColors />
      <AuthProvider>
        <SettingsProvider>
          <HeadMetadata />
          <Routes>
            <Route index element={<Login />} />
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/logout' element={<Logout />} />
            {/* <Route path='/register' element={<Register />} /> */}
            <Route path='/verifycode/:id' element={<VerifyCode />} />
            <Route path='/forgotpassword' element={<ForgotPassword />} />
            {/* <Route path='/verify' element={<Verify />} /> */}

            <Route element={<ProtectedRoute><Layout /></ProtectedRoute>}>
              <Route path='/course/:id' element={<ProtectedRoute><Course /></ProtectedRoute>} />
            </Route>

            <Route element={<ProtectedAdminRoute><AdminLayout /></ProtectedAdminRoute>}>
              <Route path='/courses' element={<ProtectedAdminRoute><Courses /></ProtectedAdminRoute>} />
              <Route path='/users' element={<ProtectedAdminRoute><Users /></ProtectedAdminRoute>} />
              <Route path='/courses/add-edit/:id?' element={<ProtectedAdminRoute><AddEditCourse /></ProtectedAdminRoute>} />
              <Route path='/control-panel' element={<ProtectedAdminRoute><ControlPanel /></ProtectedAdminRoute>} />
            </Route>
            {/* More protected routes */}
          </Routes>
        </SettingsProvider>

      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;