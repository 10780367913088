import React, { useCallback, useEffect, useMemo, useState } from 'react';
import API from '../../api/axios';
import styled from 'styled-components';
import { toast } from 'sonner';
import { Link, useParams, useNavigate } from 'react-router-dom';
import SimpleMdeReact from 'react-simplemde-editor';
import "easymde/dist/easymde.min.css";
import { FaCloud, FaFilePdf, FaFileVideo } from 'react-icons/fa';

const Container = styled.div`
  margin-bottom: 0.5rem;
  background-color: #2D2D2D;
  padding: 2rem;
  border: 1px solid #333;
  border-radius: 4px;
  padding-top: 0;
  color: white;
  max-height: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none; 

  &::-webkit-scrollbar {
    display: none;
  }

 @media (max-width: 1024px) {
    padding: 1rem;
    max-height: 100%;
    width: 100%; /* Ensures it doesn't exceed viewport width */
    margin: 0; /* Remove any potential margin that could cause overflow */
  }

  /* Prevents horizontal overflow on small screens */
  @media (max-width: 480px) {
    padding: 0.5rem;
    border: none; /* Optional: Remove border to prevent it from causing overflow */
  }
`;

const SimpleMdeWrapper = styled.div`
  cursor: text;

  /* Styling the SimpleMDE editor */
  .CodeMirror, .CodeMirror-scroll {
    font-size: 16px;
    width: 100%;
    padding: 0.8rem;
    margin-bottom: 1rem;
    border: 1px solid #333;
    border-radius: 4px;
    background: #222;
    color: white;
    box-sizing: border-box;

    &:focus {
      border-color: #EC5F40;
    }

    /* Set the text cursor (caret) color to white */
    .CodeMirror-cursor {
      border-left: 1px solid white;
    }
  }

  /* Toolbar styling */
  .editor-toolbar {
    border: 1px solid #333;
    background-color: #222;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #333;
    padding: 0.5rem; /* Adjust padding if needed */
  }

  /* Toolbar button icons */
  .editor-toolbar a,
  .editor-toolbar button {
    color: white;
    text-decoration: none; /* Ensure links are not underlined */
    background: none; /* Ensure buttons have no background */
    
    &:hover {
      color: #EC5F40; /* Change link color on hover */
    }

    i.fa {
      color: white; /* Ensure icon color inherits from the link/button */
    }
  }

  /* Active toolbar button styling */
  .editor-toolbar a.active,
  .editor-toolbar button.active {
    background-color: #EC5F40; /* Background color for active link/button */
  }

  .editor-toolbar button.active i.fa {
    color: white !important; /* Icon color for active button */
  }

  /* Preview side styling */
  .editor-preview-side {
    background: #222;
    padding: 0.8rem;
    color: white; /* Ensure text color matches */
    border: 1px solid #333; /* Match border styling of the editor */
    border-radius: 4px; /* Match border-radius of the editor */
    box-sizing: border-box; /* Ensure box-sizing is consistent */
  }

  /* Preview main styling (if applicable) */
  .editor-preview {
    background: #222;
    padding: 0.8rem;
    color: white; /* Ensure text color matches */
    border: 1px solid #333; /* Match border styling of the editor */
    border-radius: 4px; /* Match border-radius of the editor */
    box-sizing: border-box; /* Ensure box-sizing is consistent */
  }

  /* Status bar styling */
  .editor-statusbar {
    background: #222;
    border-radius: 0 0 4px 4px;
    color: white; /* Ensure text color matches */
    border-top: 1px solid #333; /* Match border-top styling */
    padding: 0.5rem; /* Adjust padding if needed */
  }

  /* Additional styling for side-by-side preview (if applicable) */
  .editor-preview-side,
  .editor-preview {
    border: 1px solid #333; /* Match the border of the editor */
    border-radius: 4px; /* Match border-radius of the editor */
    box-sizing: border-box; /* Ensure box-sizing is consistent */
  }
`;

const Section = styled.div`
  width: 100%;
  max-width: 60rem;
  margin-bottom: 3rem;

  @media (max-width: 1024px) {
    width: 80%;
  }
`;

const Input = styled.input`
  padding: 0.8rem;
  flex-grow: 1;
  border: 1px solid #444;
  border-radius: 4px;
  background: #222;
  color: white;
  box-sizing: border-box;

  &:focus {
    border-color: #EC5F40;
  }
`;

const ChapterContainer = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  padding: 1rem;
  border: 1px solid #444;
  border-radius: 8px;
  background: #333;
  position: relative;

  @media (max-width: 1024px) {
    width: 90%;
  }
`;

const SubchapterContainer = styled.div`
  position: relative;
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid #555;
  border-radius: 8px;
  background: #444;
  display: flex;
  width: 100%
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
    width: 80%;
  }
`;

const RemoveButton = styled.button`
  padding: 0.8rem 1rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: white;
  background: #DA5A50;
  border: 0px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: #EC5F40;
  }

  @media (max-width: 1024px) {
    padding: 0.8rem 1rem;
    font-size: 0.7rem;
  }
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const Button = styled.button`
  padding: 0.8rem 1.2rem;
  font-size: 0.9rem;
  font-weight: 600;
  color: white;
  background: #EC5F40;
  border: 0px;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 1rem;

  &:hover {
    background: #DA5A50;
  }

  &:disabled {
    background: #555;
    cursor: not-allowed;
  }
`;

const SectionTitle = styled.h3`
  margin-bottom: 1rem;
  color: #EC5F40;
`;

const CancelBtn = styled(Link)`
  padding: 0.8rem 1.2rem;
  font-size: 0.9rem;
  font-weight: 600;
  color: white;
  background: #555;
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background: #777;
  }
`;

const FileUploadArea = styled.div`
  display: flex;
  margin-top:1rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px dotted #ccc;
  padding: 50px;
  text-align: center;
  cursor: pointer;
  color: white;

  i {
    margin-bottom: 1rem;
    font-size: 3rem; /* Adjust font size as needed */
  }

  p {
    font-size: 1.2rem;
    line-height: 2rem;
    color: white;
  }
`;

const HiddenFileInput = styled.input`
  display: none;
`;

// Container for the file details
const FileDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

// Icon container with margin
const FileIconContainer = styled.div`
  margin-right: 1rem;
`;

// Text container for file name and size
const FileTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  p {
    margin: 0;
  }

  p:first-child {
    font-size: 1rem;
  }

  p:last-child {
    font-size: 0.8rem;
  }
`;

// Button container aligned to the right
const FileButtonContainer = styled.div`
  margin-left: auto;
`;

const DangerButton = styled.button`
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: white;
  background: #da5a50;
  border: 0px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: #ec5f40;
  }

  i {
    margin-right: 0;
  }
`;


const AddEditCourse = () => {
  const [course, setCourse] = useState({ title: '', description: '', isActive: false });
  const [chapters, setChapters] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) {
      API.get(`/courses/${id}`).then((response) => {
        setCourse(response.data);
        setChapters(response.data.chapters);
      });
    }
  }, [id]);

  const markDownOptions = useMemo(() => {
    return {
      spellChecker: false,
      hideIcons: ['fullscreen', 'side-by-side'],
      sideBySideFullscreen: false
    };
  }, []);


  const handleCourseChange = (e) => {
    setCourse({ ...course, [e.target.name]: e.target.value });
  };

  const handleChapterChange = (index, e) => {
    const newChapters = [...chapters];
    newChapters[index][e.target.name] = e.target.value;
    setChapters(newChapters);
  };

  const handleAddChapter = () => {
    setChapters([...chapters, { title: '', description: '', order: chapters.length + 1, subchapters: [] }]);
  };

  const handleSubchapterChange = (chapterIndex, subchapterIndex, e) => {
    const newChapters = [...chapters];
    newChapters[chapterIndex].subchapters[subchapterIndex][e.target.name] = e.target.value;
    setChapters(newChapters);
  };

  const handleAddSubchapter = (chapterIndex) => {
    const newChapters = [...chapters];
    newChapters[chapterIndex].subchapters.push({ title: '', description: '', fileSize: '', videoDuration: '', fileType: '', fileId: '', order: newChapters[chapterIndex].subchapters.length + 1 });
    setChapters(newChapters);
  };

  const handleRemoveSubchapter = (chapterIndex, subchapterIndex) => {
    const newChapters = [...chapters];
    newChapters[chapterIndex].subchapters.splice(subchapterIndex, 1);
    setChapters(newChapters);
  };

  const handleRemoveChapter = (chapterIndex) => {
    const newChapters = [...chapters];
    newChapters.splice(chapterIndex, 1);
    setChapters(newChapters);
  };

  const handleFileUpload = (chapterIndex, subchapterIndex, e) => {
    const file = e.target.files[0];

    if (file) {
      const newChapters = [...chapters];
      const fileSize = file.size;
      const fileType = file.type;

      newChapters[chapterIndex].subchapters[subchapterIndex].file = file;
      newChapters[chapterIndex].subchapters[subchapterIndex].file.fileSize = fileSize;
      newChapters[chapterIndex].subchapters[subchapterIndex].file.fileType = fileType;

      setChapters(newChapters);
    }
  };

  const removeFile = (chapterIndex, subchapterIndex) => {
    const newChapters = [...chapters];

    // Remove the file and its related information
    newChapters[chapterIndex].subchapters[subchapterIndex].file = null;
    newChapters[chapterIndex].subchapters[subchapterIndex].fileSize = null;
    newChapters[chapterIndex].subchapters[subchapterIndex].fileType = null;
    newChapters[chapterIndex].subchapters[subchapterIndex].fileId = null;

    setChapters(newChapters);
  };

  const handleSave = async () => {
    setIsSaving(true);
    const formData = new FormData();
    formData.append('title', course.title);
    formData.append('description', course.description);
    formData.append('isActive', course.isActive);

    chapters.forEach((chapter, chapterIndex) => {
      if (chapter.id) {
        formData.append(`chapters[${chapterIndex}][id]`, chapter.id);
      }
      formData.append(`chapters[${chapterIndex}][title]`, chapter.title);
      formData.append(`chapters[${chapterIndex}][description]`, chapter.description);
      formData.append(`chapters[${chapterIndex}][order]`, chapter.order);

      chapter.subchapters.forEach((subchapter, subchapterIndex) => {
        if (subchapter.id) {
          formData.append(`chapters[${chapterIndex}][subchapters][${subchapterIndex}][id]`, subchapter.id);
        }
        formData.append(`chapters[${chapterIndex}][subchapters][${subchapterIndex}][title]`, subchapter.title);
        formData.append(`chapters[${chapterIndex}][subchapters][${subchapterIndex}][description]`, subchapter.description);
        formData.append(`chapters[${chapterIndex}][subchapters][${subchapterIndex}][order]`, subchapter.order);

        if (subchapter.file) {
          formData.append(`chapters[${chapterIndex}][subchapters][${subchapterIndex}][file]`, subchapter.file);
        }

        if (subchapter.fileId) {
          formData.append(`chapters[${chapterIndex}][subchapters][${subchapterIndex}][fileId]`, subchapter.fileId);
        }
      });
    });

    const saveCoursePromise = new Promise(async (resolve, reject) => {
      try {
        let result = null;
        if (id) {
          result = await API.put(`/courses/${id}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        } else {
          result = await API.post('/courses', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    })

    toast.promise(saveCoursePromise, {
      loading: 'Saving course...',
      success: () => {
        setIsSaving(false);
        navigate('/courses');
        return 'Course saved with success!';
      },
      error: (error) => {
        setIsSaving(false);
        return `Error saving course:${error}`
      }
    })
  };

  const handleFileDropUpload = (event, chapterIndex, subchapterIndex) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleFileUpload(chapterIndex, subchapterIndex, { target: { files } });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <h1 style={{ color: 'white' }}>{id ? 'Edit Course' : 'Add Course'}</h1>
      <Container>

        <Section>
          <SectionTitle>Course Info</SectionTitle>
          <Input style={{ width: '100%', marginBottom: '1rem' }} type="text" name="title" defaultValue={course.title} onChange={handleCourseChange} placeholder="Course Title" />
          <SimpleMdeWrapper>
            <SimpleMdeReact name='description' value={course.description} options={markDownOptions} onChange={(value) => handleCourseChange({ target: { name: 'description', value: value } })} />
          </SimpleMdeWrapper>
          <CheckboxLabel>
            <input type="checkbox" name="isActive" checked={course.isActive} onChange={(e) => setCourse({ ...course, isActive: e.target.checked })} />
            Active
          </CheckboxLabel>
        </Section>

        <Section>
          <SectionTitle>Chapters</SectionTitle>
          {chapters.map((chapter, chapterIndex) => (
            <ChapterContainer key={chapterIndex}>
              <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
                <Input type="text" name="title" defaultValue={chapter.title} onChange={(e) => handleChapterChange(chapterIndex, e)} placeholder="Chapter Title" required />
                <RemoveButton onClick={() => handleRemoveChapter(chapterIndex)}>X</RemoveButton>
              </div>
              <SimpleMdeWrapper>
                <SimpleMdeReact value={chapter.description} options={markDownOptions} onChange={(value) => handleChapterChange(chapterIndex, { target: { name: 'description', value: value } })} />
              </SimpleMdeWrapper>

              <SectionTitle>Subchapters</SectionTitle>
              {chapter.subchapters.map((subchapter, subchapterIndex) => (
                <SubchapterContainer key={subchapterIndex}>
                  <div style={{ flexGrow: 1 }}>
                    <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
                      <Input type="text" name="title" defaultValue={subchapter.title} onChange={(e) => handleSubchapterChange(chapterIndex, subchapterIndex, e)} placeholder="Subchapter Title" required />
                      <RemoveButton onClick={() => handleRemoveSubchapter(chapterIndex, subchapterIndex)}>X</RemoveButton>
                    </div>
                    <SimpleMdeWrapper>
                      <SimpleMdeReact value={subchapter.description} options={markDownOptions} onChange={(value) => handleSubchapterChange(chapterIndex, subchapterIndex, { target: { name: 'description', value: value } })} />
                    </SimpleMdeWrapper>
                    {subchapter.file ? (
                      <FileDetailsContainer>
                        <FileIconContainer>
                          {subchapter.file.type == 'video/mp4' ? <FaFileVideo size={24} /> : <FaFilePdf size={24} />}
                        </FileIconContainer>
                        <FileTextContainer>
                          <p>{subchapter.file.name}</p>
                          <p>{(subchapter.file.fileSize / (1024 * 1024)).toFixed(2)} MB</p>
                        </FileTextContainer>
                        <FileButtonContainer>
                          <DangerButton onClick={() => removeFile(chapterIndex, subchapterIndex)}>
                            X
                          </DangerButton>
                        </FileButtonContainer>
                      </FileDetailsContainer>
                    ) : (
                      <FileUploadArea
                        onDrop={(e) => handleFileDropUpload(e, chapterIndex, subchapterIndex)}
                        onDragOver={handleDragOver}
                        onClick={() =>
                          document
                            .getElementById(`fileUploadInput-${chapterIndex}-${subchapterIndex}`)
                            .click()
                        }
                      >
                        <HiddenFileInput
                          type="file"
                          id={`fileUploadInput-${chapterIndex}-${subchapterIndex}`}
                          onChange={(e) => handleFileUpload(chapterIndex, subchapterIndex, e)}
                          accept=".pdf, .mp4, .mkv .xlsx .xls "
                        />
                        <FaCloud />
                        <p>Încarcă fișier</p>
                      </FileUploadArea>
                    )}
                    {/* <input type="file" onChange={(e) => handleFileUpload(chapterIndex, subchapterIndex, e)} required /> */}
                  </div>
                </SubchapterContainer>
              ))}
              <Button onClick={() => handleAddSubchapter(chapterIndex)}>+ Add Subchapter</Button>
            </ChapterContainer>
          ))}
          <Button onClick={handleAddChapter}>+ Add Chapter</Button>
        </Section>
        <div>
          <Button onClick={handleSave} disabled={isSaving}>Save</Button>
          <CancelBtn to={'/courses'}>Cancel</CancelBtn>
        </div>
      </Container>
    </>
  );
};

export default AddEditCourse;