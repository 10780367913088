import React, { useEffect, useState } from 'react';
import { FaPlayCircle, FaFileAlt, FaPlus, FaMinus } from 'react-icons/fa';
import styled from 'styled-components';
import { useSettings } from '../providers/SettingsProvider';

const ChapterTitleContainer = styled.div`
    padding: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;

const Badge = styled.div`
    width: fit-content;
    background-color: #F0F4F7;
    color: black;
    padding: 0.3rem 1rem;
    border-radius: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    text-align: center;
`;

const ChapterCardsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 25rem;
    flex-shrink: 0;
    @media (max-width: 1024px) {
        width: fit-content;
    }
`;

const ChapterCard = styled.div`
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 0;
    overflow: hidden; // To ensure content doesn't overflow during animation

    &:before {
        content: "";
        position: absolute;
        z-index: -1;
        inset: 0;
        padding: 2px;
        border-radius: 15px;
        background: linear-gradient(90deg, #EC5F40 0%, #666666 100%);
        -webkit-mask: 
            linear-gradient(#fff 0 0) content-box, 
            linear-gradient(#fff 0 0);
        mask: 
            linear-gradient(#fff 0 0) content-box, 
            linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
    }
`;

const SubchapterList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: ${({ $isExpanded }) => ($isExpanded ? '1000px' : '0')}; // Adjust max-height as needed
    overflow: hidden;
    transition: max-height 0.3s ease-out; // Animation for expanding and collapsing
`;

const SubchapterItem = styled.div`
    display: flex;
    align-items: center;
    background: ${({ $isSelected }) => ($isSelected ? '#99999942' : 'none')};
    color:${({ $isAccessible }) => ($isAccessible ? '' : 'gray')};
    border-radius: 8px;
    padding: 1rem;
    cursor: pointer;
    transition: background 0.3s ease;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;

    &:hover {
        background: ${({ $isAccessible }) => ($isAccessible ? '#f0f0f0' : '')};
        color: ${({ $isAccessible }) => ($isAccessible ? 'black' : '')};
        cursor: ${({ $isAccessible }) => ($isAccessible ? 'pointer' : 'not-allowed')};
    }
`;

const IconWrapper = styled.div`
    flex-shrink: 0;
    margin-right: 10px;
    font-size: 1.5rem;
`;

const SubchapterTitle = styled.span`
    flex-grow: 1;
`;

const VideoInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const Divider = styled.div`
    height: 0.1px;
    background: #ddd;
    width: 100%;
`;

const CustomCheckbox = styled.input.attrs({ type: 'checkbox' })`
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #EC5F40;
    border-radius: 4px;
    background-color: transparent;
    position: relative;
    cursor: pointer;
    margin-right: 10px;

    &:checked {
        background-color: #EC5F40;
    }

    &:checked::before {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        background-color: transparent;
        border-radius: 4px;
        position: absolute;
        left: 0.3rem;
        top: 0.3;
        width: 0.2rem;
        height: 0.7rem;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`;


const CourseSidebar = ({ course, selectedSubchapter, handleSubchapterClick }) => {
    const [expandedChapterId, setExpandedChapterId] = useState(course.chapters[0]?.id);
    const { settings } = useSettings();
    const [isManualProgressionOn, setIsManualProgressionOn] = useState(false);

    useEffect(() => {
        if (selectedSubchapter) {
            const chapterContainingSubchapter = course.chapters.find(chapter =>
                chapter.subchapters.some(subchapter => subchapter.id === selectedSubchapter.id)
            );
            if (chapterContainingSubchapter) {
                setExpandedChapterId(chapterContainingSubchapter.id);
            }
        }
        if (settings){
            const manualProgressionSetting = settings.find(s => s.key === 'enable_auto_course_progression');
            if (manualProgressionSetting && manualProgressionSetting.value === 'true') {
                setIsManualProgressionOn(true);
            } 
        }
        
    }, [selectedSubchapter, course.chapters]);

    const formatDuration = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    const handleExpandCollapse = (e, chapterId) => {
        e.stopPropagation();
        setExpandedChapterId(prevId => (prevId === chapterId ? null : chapterId));
    };

    const checkIfSubChapterIsAccessible = (subchapter) => {
        const allSubchapters = course.chapters.flatMap(chapter => chapter.subchapters);
        const currentIndex = allSubchapters.findIndex(sc => sc.id === subchapter.id);

        const previousSubchapters = allSubchapters.slice(0, currentIndex);
        const nextUncompletedSubchapter = allSubchapters.slice(currentIndex + 1).find(sc => !sc.completed);
        let isAccessible = previousSubchapters.every(sc => sc.completed) || subchapter.completed || (nextUncompletedSubchapter && nextUncompletedSubchapter.id === subchapter.id);
        isAccessible = isManualProgressionOn ? true : isAccessible

        return isAccessible;
    }

    return (
        <ChapterCardsContainer>
            {course.chapters.map(chapter => (
                <ChapterCard key={chapter.id}>
                    <ChapterTitleContainer onClick={(e) => handleExpandCollapse(e, chapter.id)}>
                        <h3>{chapter.title}</h3>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', alignItems: 'center', justifyContent: 'center' }}>
                            <Badge>{`${chapter.completedCount}/${chapter.subchapters.length}`}</Badge>
                            <div style={{ cursor: 'pointer', marginTop: '0.3rem' }} onClick={(e) => handleExpandCollapse(e, chapter.id)}>
                                {expandedChapterId === chapter.id ? <FaMinus /> : <FaPlus />}
                            </div>
                        </div>
                    </ChapterTitleContainer>
                    <SubchapterList $isExpanded={expandedChapterId === chapter.id}>
                        {chapter.subchapters.map((subchapter, index, arr) => (
                            <React.Fragment key={subchapter.id}>
                                <SubchapterItem
                                    data-subchapter-id={subchapter.id}
                                    $isSelected={selectedSubchapter?.id === subchapter.id}
                                    $isAccessible={checkIfSubChapterIsAccessible(subchapter)}
                                    onClick={() => handleSubchapterClick(subchapter.id)}
                                >
                                    <IconWrapper>
                                        {subchapter.file?.type?.includes('video')
                                            ? <FaPlayCircle size={20} color={selectedSubchapter?.id === subchapter.id ? '#FFFFFF' : '#EC5F40'} />
                                            : <FaFileAlt color={selectedSubchapter?.id === subchapter.id ? '#FFFFFF' : '#EC5F40'} size={20} />}
                                    </IconWrapper>
                                    <SubchapterTitle>
                                        {subchapter.title}
                                    </SubchapterTitle>
                                    {subchapter.file?.type?.includes('video') && (
                                        <VideoInfo>
                                            <span>{formatDuration(subchapter.file.videoDuration)}</span>
                                        </VideoInfo>
                                    )}
                                    <CustomCheckbox
                                        checked={subchapter.completed}
                                    />
                                </SubchapterItem>
                                {index < arr.length - 1 && <Divider />}
                            </React.Fragment>
                        ))}
                    </SubchapterList>
                </ChapterCard>
            ))}
        </ChapterCardsContainer>
    );
};

export default CourseSidebar;