
import { ToggleContainer, ToggleLabel, ToggleSwitch } from '../../../components/common/styledComponents/controlPanelStyles';

const Autoplay = ({ isEnabledManualProgression, isEnabledAutoplay, handleManualProgressionToggle, handleAutoplayToggle }) => {
    return (
        <>
            <ToggleContainer>
                <ToggleLabel>
                    <ToggleSwitch checked={isEnabledManualProgression} onChange={handleManualProgressionToggle} />
                    Enable manual course progression
                </ToggleLabel>
            </ToggleContainer>
            <ToggleContainer>
                <ToggleLabel>
                    <ToggleSwitch checked={isEnabledAutoplay} onChange={handleAutoplayToggle} />
                    Enable autoplay
                </ToggleLabel>
            </ToggleContainer>
        </>
    )

}

export default Autoplay;