import React, { createContext, useContext, useState, useEffect } from 'react';
import API from '../api/axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [error, setError] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            // Optionally verify token validity and set user state accordingly
            API.post('/auth/verify-token').then(response => {
                setUser(response.data.user); // Assuming the response will have user data
                localStorage.setItem('user', JSON.stringify(response.data.user)); // Update local storage if needed
            }).catch(error => {
                console.log("Token verification failed:", error);
                signOut(); // If token verification fails, sign the user out
            });
        }
    }, []);

    const signIn = async (email, password) => {
        try {
            const response = await API.post('/auth/sign-in', { email, password });
            setUser(response.data);
            localStorage.setItem('token', response.data.accessToken);
            localStorage.setItem('user', JSON.stringify(response.data)); // Store user data in local storage
            return response;
        } catch (err) {
            setError(err.response.data.message);
            return err.response;
        }
    };

    const signOut = () => {
        setUser(null);
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        API.defaults.headers.common['Authorization'] = '';
    };

    return (
        <AuthContext.Provider value={{ user, signIn, signOut, error }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
