import styled from 'styled-components';

export const Card = styled.div`
  width: 25rem;
  display: flex;
  flex-direction: column;
  padding: 5rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 0;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    inset: 0;
    padding: 1px;
    border-radius: 15px;
    background: linear-gradient(180deg, #9F9B9B 0%, #666666 100%);
    -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
            mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
  }

  @media (max-width: 600px) {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.25rem;
  }
`;

export const CardHeader = styled.div`
  width: 100%;
  padding: 10px 0;
  margin-bottom: 3rem;
  font-size: 2rem;
  font-weight: bold;
`;

export const CardBody = styled.div`
  width: 100%;
`;

export const CardFooter = styled.div`
  width: 100%;
  border-top: 1px solid #ddd;
`;

export const CenterContainer = styled.div`
  font-family: Poppins;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Button = styled.button`
  cursor: pointer;
  width: 100%;
  padding: 1.3rem;
  font-size: 1.3rem;
  font-weight: 600;
  color: white;
  background: #EC5F40;
  border: 0px;
  border-radius: 8px;
  margin-bottom: 1rem;

  &:hover {
      background: #DA5A50;
  }
`;

export const Copyright = styled.div`
  font-size: 0.9rem;
  color: #9F9B9B;
  margin-top: 0.2rem;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(90deg, #EC5F40, #999999);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
`;