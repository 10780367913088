import styled from "styled-components";

const Input = styled.input`
  padding: 0.4rem;
  width: 60%;
  border: 1px solid #444;
  border-radius: 4px;
  background: #222;
  color: white;

  &:focus {
    border-color: #EC5F40;
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.4rem;
`;

const platforms = [
    { name: "YouTube", placeholder: "https://www.youtube.com/" },
    { name: "Facebook", placeholder: "https://www.facebook.com/" },
    { name: "TikTok", placeholder: "https://www.tiktok.com/" },
    { name: "Instagram", placeholder: "https://www.instagram.com/" },
];

const SocialMediaLinks = ({ socialMediaLinks, handleSocialMediaChange }) => {
    const links = platforms.map((platform) => {
        const existingLink = socialMediaLinks ? socialMediaLinks.find((link) => link.platform == platform.name) : null;
        return {
            platform: platform.name,
            value: existingLink ? existingLink.value : "",
            placeholder: platform.placeholder,
        };
    });

    return (
        <>
            {links.map((link, index) => (
                <div key={index}>
                    <Label>{link.platform}</Label>
                    <Input
                        type="text"
                        name={link.platform}
                        defaultValue={link.value}
                        placeholder={link.placeholder}
                        onChange={(event) => handleSocialMediaChange(event, index, link.platform)}
                    />
                </div>
            ))}
        </>
    );
};

export default SocialMediaLinks;