import React, { useEffect, useState } from 'react';

import Table from '../../components/Table';
import API from '../../api/axios';
import { Link } from 'react-router-dom';
import { toast } from 'sonner';
import { Button, AddEditBtn } from '../../components/common/styledComponents/coursesStyles';

const Courses = () => {
    const [courses, setCourses] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'asc' });

    useEffect(() => {
        fetchCourses();
    }, [currentPage, searchQuery, sortConfig]);

    const fetchCourses = async () => {
        try {
            const result = await API.get(`/courses`, {
                params: {
                    page: currentPage,
                    search: searchQuery,
                    sortBy: sortConfig.key,
                    sortDirection: sortConfig.direction
                }
            });
            setCourses(result.data.courses);
            setTotalPages(result.data.totalPages);
        } catch (error) {
            console.error('Error fetching courses:', error);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1); // Reset to the first page on search
    };

    const handleSortChange = (key) => {
        setSortConfig((prev) => ({
            key,
            direction: prev.key === key ? (prev.direction === 'asc' ? 'desc' : 'asc') : 'asc'
        }));
    };


    const handleDelete = async (id) => {
        try {
            await API.delete(`/courses/${id}`);
            toast.success("Course deleted successfully.");
            fetchCourses();
        } catch (error) {
            console.error('Error deleting course:', error);
            toast.error("There was an error deleting the course.");
        }
    }

    const columns = [
        {
            header: 'Title',
            accessor: 'title',
            sortable: true,
            Cell: (cellData) => (
                cellData.isActive ? (
                    <Link to={`/course/${cellData.id}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                        {cellData.title}
                    </Link>
                ) : (
                    <span onClick={() => toast.info('This course is not active')} style={{ cursor: 'pointer', color: 'inherit' }}>
                        {cellData.title}
                    </span>
                )
            )
        },
        {
            header: 'Description',
            accessor: 'description',
            sortable: true,
            Cell: ({ description }) => (
                description.length > 25 ? `${description.substring(0, 25)}...` : description
            )
        },
        {
            header: 'Chapters',
            accessor: 'chapters',
            Cell: (cellData) => (
                cellData?.chapters?.length || '-'
            )
        },
        {
            header: 'Subchapters',
            accessor: 'chapters.subchapters',
            Cell: (cellData) => {
                // Calculate total subchapters
                const totalSubchapters = cellData.chapters.reduce((total, chapter) => {
                    return total + (chapter.subchapters ? chapter.subchapters.length : 0);
                }, 0);

                return totalSubchapters;
            }
        },
        {
            header: 'Active Course',
            accessor: 'isActive',
            Cell: (cellData) => (
                cellData.isActive ? 'Yes' : 'No'
            )
        },
        {
            header: 'Actions',
            accessor: 'id',
            sortable: false,
            Cell: (cellData) => (
                <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                    <AddEditBtn to={`/courses/add-edit/${cellData.id}`}>Edit</AddEditBtn>
                    <Button onClick={() => handleDelete(cellData.id)}>Delete</Button>
                </div>
            )
        }
    ];

    return (
        <div style={{ padding: '2rem' }}>
            <h1 style={{ color: 'white' }}>Courses</h1>
            <AddEditBtn style={{ marginBottom: '2rem' }} to={'/courses/add-edit'}>+ Add Course</AddEditBtn>
            <Table
                columns={columns}
                data={courses}
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                searchQuery={searchQuery}
                onSearchChange={handleSearchChange}
                onSortChange={handleSortChange}
                sortConfig={sortConfig}
            />
        </div>
    );
};

export default Courses;
