import styled from "styled-components";
import { Container } from "../components/common/styles";
import Input from "../components/common/Input";
import { forgotPassword } from "../api/users";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { CenterContainer, NextButton } from '../components/common/styledComponents/forgotPasswordStyles'

const ForgotPassword = () => {
    const navigate = useNavigate();

    const handleEmailSend = async (e) => {
        e.preventDefault();
        const data = {
            email: e.target.email.value,
        }
        const forgotPasswordPromise = new Promise(async (resolve, reject) => {
            try {
                const result = await forgotPassword(data);
                if (result.status === 200) {
                    resolve(result);
                } else {
                    reject(new Error(result.message ?? result.message + ": Please try again!"));
                }
            } catch (error) {
                reject(error);
            }
        })


        toast.promise(forgotPasswordPromise, {
            loading: 'Sending email...',
            success: () => {
                setTimeout(() => {
                    navigate('/login');
                }, 5000);
                return `Email sent with success! You will be redirected to the login page.`;
            },
            error: (error) => {
                return `Failed to send email: ${error.message}`;
            },
        });
    }

    return (
        <Container>
            <CenterContainer>
                <>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <h1 style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap', margin: 0, fontWeight: 600, fontSize: '3rem', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
                            FORGOT PASSWORD?
                        </h1>
                        <p style={{ color: '#9F9B9B', margin: 0, fontSize: '1rem', textAlign: 'center' }}>
                            Enter your email and we will send a verification code
                        </p>
                        <form style={{ width: '100%', textAlign: 'center' }} onSubmit={handleEmailSend}>
                            <Input style={{ marginTop: '1rem' }} name="email" type="email" placeholder="Enter your email" required />
                            <NextButton type="submit">Next</NextButton>
                        </form>
                    </div>
                </>
            </CenterContainer>
        </Container>
    )
}

export default ForgotPassword;