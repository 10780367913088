import styled from 'styled-components';

export const ControlPanelContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 2rem;
    color: white;
`;

export const ToggleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

export const ToggleLabel = styled.label`
    color: white;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

export const ToggleSwitch = styled.input.attrs({ type: 'checkbox' })`
    appearance: none;
    width: 40px;
    height: 20px;
    background-color: #555;
    border-radius: 20px;
    position: relative;
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:checked {
        background-color: #EC5F40;
    }

    &:before {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        background-color: white;
        border-radius: 50%;
        top: 2px;
        left: 2px;
        transition: transform 0.3s ease;
    }

    &:checked:before {
        transform: translateX(20px);
    }

    @media (max-width: 1024px) {
        width: 55px;
    } 
`;

export const SaveButton = styled.button`
    padding: 8px 16px;
    background-color: #EC5F40;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    margin-top: 10px;
    width: fit-content;
    align-self: flex-start;

    &:hover {
        background-color: #d9534f;
    }
`;