// ProtectedAdminRoute.js
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Ensure this path is correct

function ProtectedAdminRoute({ children }) {
    const { user } = useAuth();
    const location = useLocation();
    if (!user || user.role.name !== 'Admin') {
        // Redirects to the login page while preserving the intended destination
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
}

export default ProtectedAdminRoute;
