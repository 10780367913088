import styled from 'styled-components';

export const CenterContainer = styled.div`
  font-family: Poppins;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

export const NextButton = styled.button`
  cursor: pointer;
  background: inherit;
  color: white;
  padding: 0.5rem 2rem;
  border: 1px solid #EC5F40;
  border-radius: 8px;
  margin-top: 1rem;
`;