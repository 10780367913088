import React from 'react';
import styled from 'styled-components';

// Styled components for the switch
const SwitchContainer = styled.label`
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    width: 40px;  
    height: 20px;
    @media (max-width: 1024px) {
        width: 55px;
    } 
`;

const SwitchInput = styled.input.attrs({ type: 'checkbox' })`
    opacity: 0;
    width: 0;
    height: 0;
    @media (max-width: 1024px) {
        width: 55px;
    } 
`;

const Slider = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc; // Default background color
    transition: .4s;
    border-radius: 20px; // Makes it round

    &::before {
        position: absolute;
        content: '';
        height: 16px;  // Adjust size as needed
        width: 16px;   // Adjust size as needed
        border-radius: 50%;
        left: 2px;    // Padding from left
        bottom: 2px;  // Padding from bottom
        background-color: white;
        transition: .4s;
    }

    // Change background color and knob position when checked
    ${SwitchInput}:checked + & {
        background-color: #EC5F40; // Fill color when checked
    }

    ${SwitchInput}:checked + &::before {
        transform: translateX(20px); // Moves the circle to the right
    }
`;

const Switch = ({ checked, onChange }) => (
    <SwitchContainer>
        <SwitchInput type="checkbox" checked={checked} onChange={onChange} />
        <Slider />
    </SwitchContainer>
);

export default Switch;
