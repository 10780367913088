import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from './styledComponents/courseStyles';
import { toast } from 'sonner';

// Basic Modal and Wrapper styles
const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem; /* Added padding for small screens */
  box-sizing: border-box;
`;

const ModalWrapper = styled.div`
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  padding: 0.1rem;
  min-width: 50rem;
  max-width: 90%; /* Adjusted max-width to ensure it doesn't overflow */
  min-height: 10rem;
  
  /* Gradient border effect */
  &::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: linear-gradient(90deg, #EC5F40 0%, #666666 100%);
    border-radius: 8px;
    z-index: -1;
  }

  @media (max-width: 1024px) {
    min-width: 90%; /* Adjusted for smaller screens */
    max-width: 100%;
  }

  @media (max-width: 600px) {
    min-width: 100%; /* Full width on very small screens */
    padding: 0.1rem; /* Less padding on very small screens */
  }
`;

const ModalContent = styled.div`
  position: relative;
  padding: 2rem;
  border-radius: 8px;
  background-color: #121212;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const InputWithButton = styled.div`
  position: relative;
  width: 100%; /* Full width of its container */
  margin: 1rem 0; /* Margin between input and other elements */
`;

const InputField = styled.input`
  width: calc(100% - 5rem); /* Adjust width to leave space for the button */
  height: 2.5rem; /* Set consistent height */
  padding-right: 4rem; /* Space for the button */
  padding-left: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #282828;
  color: #fff;
  cursor: pointer;
  user-select: none;

  @media (max-width: 600px) {
    width: calc(100% - 4rem); /* Adjust width for smaller screens */
    padding-right: 3rem; /* Adjust padding for smaller screens */
  }
`;

const CopyButton = styled.button`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  min-width: 5rem;
  height: 100%;
  background-color: transparent;
  color: #EC5F40;
  border: none;
  padding: 0 1rem;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const SocialLinksContainer = styled.div`
  display: flex;
  flex-wrap: wrap; /* Allow wrapping */
  justify-content: start; /* Center align the items */
  gap: 1rem; /* Space between images */
  width: 100%; /* Full width of its container */
  margin-top: 1rem; /* Margin between social links and other elements */
  margin-bottom: 1rem; /* Margin between social links and other elements */
`;

const SocialLink = styled.a`
  display: flex; /* Use flexbox to center the image */
  align-items: center; /* Center the image vertically */
  justify-content: center; /* Center the image horizontally */
  width: 4rem; /* Standardized width */
  height: 4rem; /* Standardized height */
  border: 2px solid #282828; /* Default border */
  border-radius: 4px; /* Rounded corners */
  overflow: hidden; /* Hide overflow */
  transition: border 0.3s; /* Smooth border transition on hover */

  &:hover {
    border: 2px solid #EC5F40; /* Orange border on hover */
  }

  img {
    width: 2rem; /* Ensure image covers the container */
    height: 2rem; /* Ensure image covers the container */
    object-fit: contain; /* Maintain aspect ratio */
  }
`;


// ShareSocial Component
const ShareSocial = ({ url, socialTypes, isOpen, onClose }) => {
    const handleOverlayClick = (event) => {
        if (event.target === event.currentTarget) {
            onClose(); // Only trigger onClose if the click is on the overlay
        }
    };

    const getShareUrl = (type, url) => {
        const encodedUrl = encodeURIComponent(url);
        switch (type) {
            case 'facebook':
                return `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
            case 'twitter':
                return `https://twitter.com/intent/tweet?url=${encodedUrl}`;
            case 'reddit':
                return `https://reddit.com/submit?url=${encodedUrl}`;
            case 'linkedin':
                return `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}`;
            case 'whatsapp':
                return `https://wa.me/?text=${encodedUrl}`;
            default:
                return '#';
        }
    };

    const getSocialImageUrl = (type) => {
        switch (type) {
            case 'facebook':
                return '/assets/social-media/facebook.png';
            case 'twitter':
                return '/assets/social-media/twitter.png';
            case 'reddit':
                return '/assets/social-media/reddit.png';
            case 'linkedin':
                return '/assets/social-media/linkedin.png';
            case 'whatsapp':
                return '/assets/social-media/whatsapp.png';
            default:
                return '/assets/social-media/default.png';
        }
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                toast.success('URL copied to clipboard!');
            })
            .catch(err => alert('Failed to copy URL: ', err));
    };

    return (
        <>
            {isOpen && (
                <ModalContainer onClick={handleOverlayClick}>
                    <ModalWrapper>
                        <ModalContent>
                            <h2 style={{ margin: 0 }}>Share</h2>
                            <SocialLinksContainer>
                                {socialTypes.map((type) => (
                                    <SocialLink
                                        key={type}
                                        href={getShareUrl(type, url)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img src={getSocialImageUrl(type)} alt={type} />
                                    </SocialLink>
                                ))}
                            </SocialLinksContainer>
                            <InputWithButton>
                                <InputField
                                    type="text"
                                    readOnly
                                    value={url}
                                    onClick={() => copyToClipboard(url)}
                                />
                                <CopyButton onClick={() => copyToClipboard(url)}>
                                    Copy
                                </CopyButton>
                            </InputWithButton>
                            <div style={{ width: '100%' }}>
                                <Button onClick={onClose}>Close</Button>
                            </div>
                        </ModalContent>
                    </ModalWrapper>
                </ModalContainer>
            )}
        </>
    );
};

ShareSocial.propTypes = {
    url: PropTypes.string.isRequired,
    socialTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ShareSocial;
