import React, { createContext, useContext, useState, useEffect } from 'react';
import API from '../api/axios';

const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
    const [settings, setSettings] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const response = await API.get('/system-settings');
                setSettings(response.data);
                localStorage.setItem('settings', JSON.stringify(response.data));
                setLoading(false);
            } catch (err) {
                console.error("Eroare la obținerea setărilor:", err);
                setError(err.message);
                setLoading(false);
            }
        };

        fetchSettings();

        const handleSettingsUpdate = () => fetchSettings(); // Fetch when settings are updated
        window.addEventListener('settingsUpdated', handleSettingsUpdate); // use this event listener to live update settings; if live updates are needed, a websocket must be implemented

        return () => window.removeEventListener('settingsUpdated', handleSettingsUpdate);
    }, []);

    if (loading) {
        return <div>Loading settings...</div>;  // Blochează randarea aplicației până când setările sunt încărcate
    }

    return (
        <SettingsContext.Provider value={{ settings, error }}>
            {children}
        </SettingsContext.Provider>
    );
};

export const useSettings = () => useContext(SettingsContext);