import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import API from '../api/axios';

const SidebarContainer = styled.div`
    padding: 1rem;
    min-width: 250px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: #2c2c2c; /* Darker background for contrast */
     @media (max-width: 1024px) {
        min-width: 200px; /* Setează min-width la 200px pe ecrane mai mici */
    }
`;

const SidebarItem = styled(Link)`
    text-decoration: none;
    color: white;
    padding: 1rem;
    font-size: 1rem;
    border-radius: 8px;
    transition: background-color 0.3s, color 0.3s;
    background-color: ${({ isActive }) => isActive ? '#EC5F40' : '#1e1e1e'};

    &:hover {
        background-color: #EC5F40;
        color: white;
    }
`;

const AdminSidebar = () => {
    const [activeCourseId, setActiveCourseId] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const fetchActiveCourseId = async () => {
            try {
                const result = await API.get('/courses/active-course');
                setActiveCourseId(result.data); // Adjust based on your API response
            } catch (error) {
                console.error('Error fetching active course:', error);
            }
        };

        fetchActiveCourseId();
    }, []);

    return (
        <SidebarContainer>
            <SidebarItem to={`/course/${activeCourseId}`}>View active course</SidebarItem>
            <SidebarItem isActive={location.pathname == '/courses' ? true : false} to="/courses">Courses</SidebarItem>
            <SidebarItem isActive={location.pathname == '/users' ? true : false} to="/users">Users</SidebarItem>
            <SidebarItem isActive={location.pathname == '/control-panel' ? true : false} to="/control-panel">Control Panel</SidebarItem>
            <SidebarItem to="/logout">Logout</SidebarItem>
        </SidebarContainer>
    );
};

export default AdminSidebar;
