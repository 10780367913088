import API from './axios';

export const registerUser = async (data) => {
    try {
        const response = await API.post('/users/register', data);
        return response; // Ensure to return the data from the API response
    } catch (e) {
        console.error('Could not register user:', e);
        return e.response; // Handle error case
    }
}

export const forgotPassword = async (data) => {
    try {
        const response = await API.post('/users/verification-email', data);
        return response;
    } catch (e) {
        console.error('Could not send verification email:', e);
        return e?.message || ''; // Handle error case
    }
}

export const verifyCode = async (data) => {
    try {
        const response = await API.post('/users/verify-code', data);
        return response;
    } catch (e) {
        console.error('Could not send verification email:', e);
        return e.response; // Handle error case
    }
}

export const resetPassword = async (data) => {
    try {
        const response = await API.post('/users/reset-password', data);
        return response;
    } catch (e) {
        console.error('Failed to reset password:', e);
        return e.response; // Handle error case
    }
}