import styled from 'styled-components';

export const CenterContainer = styled.div`
  font-family: Poppins;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

export const NextButton = styled.button`
  cursor: pointer;
  background: inherit;
  color: white;
  padding: 0.5rem 2rem;
  border: 1px solid #EC5F40;
  border-radius: 8px;
  margin-top: 1rem;
`;

export const CodeContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 1rem;
`;

export const CodeInput = styled.input`
  width: 3rem;
  height: 3rem;
  border: 2px solid #ccc;
  border-radius: 6px;
  font-size: 1.5rem;
  text-align: center;
  background-color: #121212;
  color: white;
  caret-color: transparent;
  box-shadow: 0 0 0 2px #EC5F40 inset;
  outline: none;
  border: none;

  &:focus {
    border-color: #EC5F40;
    box-shadow: 0 0 0 3px #EC5F40 inset;
  }
`;