import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaCommentDots, FaTrash } from 'react-icons/fa';
import { QuestionInput } from './common/styles';
import { toast } from 'sonner';
import API from '../api/axios';
import { useAuth } from '../features/AuthContext';
import { MdSkipNext } from 'react-icons/md';

// Styled components

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
`;

const QuestionTitle = styled.div`
    font-size: 1.2rem;
    font-weight: bold;
`;

const DaysAgo = styled.span`
    font-size: 0.9rem;
    color: #EC5F40;
    margin-bottom: auto;
`;

const Subtitle = styled.div`
    font-size: 1rem;
    color: #555;
    margin-top: 0.2rem;
`;

const Answer = styled.div`
    color: white;
    margin-top: 3rem;
`;

const StatusRow = styled(Row)`
    display: flex;
    align-items: center;
    gap: 1rem;
    color: white;
    border: 1px solid #EC5F4094;
    border-radius: 8px;
    // padding: 1rem;
`;

const AddAnswerButton = styled.div`
    color: #EC5F40;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const ChatIcon = styled(FaCommentDots)`
    margin-right: 5px;
    font-size: 1.2rem;
`;

const Button = styled.button`
    cursor: pointer;
    width: fit-content;
    padding: 1rem;
    font-size: 0.8rem;
    font-weight: 600;
    color: white;
    background: #EC5F40;
    border: 0px;
    border-radius: 8px;
    margin-bottom: 1rem;

    &:hover {
        background: #DA5A50;
    }
`

const NextButton = styled.button`
    cursor: pointer;
    width: fit-content;
    padding: 1rem;
    font-size: 0.8rem;
    font-weight: 600;
    color: white;
    background: #EC5F40;
    border: 0px;
    border-radius: 8px;

    &:hover {
        background: #DA5A50;
    }
`

const AskQuestionButton = styled.button`
    background-color: #EC5F40;
    width: fit-content;
    color: white;
    border: none;
    border-radius: 0px 8px 8px 8px;
    padding: 0.8rem 1.5rem;
    display: flex;
    align-items: center;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #d9534f;
    }

    svg {
        margin-right: 8px;
    }
`;

const QAWrapper = styled.div`
    background-color: #1b1b1b;
    border-radius: 8px;
    padding: '';
`

const ViewMoreWrapper = styled.div`
    padding: '';
    text-align:center;
`

const TestWrapper = styled.div`
    width: 70%;
    @media (max-width: 1024px) {
        width: 100%!important;
    }
`

const QAItem = ({ question, onSubmitAnswer, onDelete }) => {
    const [isAnswering, setIsAnswering] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [questions, setQuestions] = useState([]);

    const { user } = useAuth();

    const handleAddAnswer = () => {
        setIsAnswering(true);
    }

    const handleDeleteClick = (questionId) => {
        toast.warning('Are you sure you want to delete this question?', {
            action: {
                label: 'Delete',
                onClick: () => onDelete(questionId)
            },
            cancel: {
                label: 'Cancel'
            }
        })
    }

    const onSubmitEdit = async (e, questionId) => {
        e.preventDefault();

        const editedAnswer = e.target.answer.value;
        if (!editedAnswer || editedAnswer.trim() === '') {
            return toast.warning('Answer cannot be empty');
        }

        const data = {
            answer: editedAnswer,
            userId: user.id,
            questionId: questionId
        };

        try {
            const result = await API.put(`/answers/${question.answer.id}`, data);
            if (result.status === 200) {
                // Update the state with the edited answer
                setQuestions(prevQuestions =>
                    prevQuestions.map(question =>
                        question.id === questionId
                            ? { ...question, answer: result.data }
                            : question
                    )
                );
                toast.success('Answer updated successfully');
            } else {
                toast.error('Failed to update the answer');
            }
        } catch (error) {
            toast.error('An error occurred while updating the answer');
        }
    };

    const getDaysAgo = (createdAt) => {
        // Normalize the date to remove time part
        const normalizeDate = (date) => new Date(date.setHours(0, 0, 0, 0));

        const createdDate = normalizeDate(new Date(createdAt));
        const currentDate = normalizeDate(new Date());

        // Calculate the difference in days
        const diffTime = currentDate - createdDate;
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

        // Return the appropriate string
        return diffDays > 1
            ? `${diffDays} days ago`
            : diffDays === 1
                ? 'Yesterday'
                : 'Today';
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
            <QAWrapper>
                <Row style={{ padding: '1rem' }}>
                    <div>
                        <QuestionTitle>{`${question?.user?.lastName ?? ''} ${question?.user?.firstName ?? ''}`}</QuestionTitle>
                        <Subtitle>{question.description}</Subtitle>
                    </div>
                    <DaysAgo>{getDaysAgo(question.createdAt)}</DaysAgo>
                </Row>
                <Answer style={{ padding: '1rem' }}>{question.question}</Answer>
            </QAWrapper>
            {user.role.name == 'Admin' && (
                <Button onClick={() => handleDeleteClick(question.id)} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.5rem', margin: '0', textAlign: 'center' }}><FaTrash /><span>Delete Comment</span></Button>
            )}
            {question.answer ? (
                <StatusRow>
                    {isEditing ? (
                        <form
                            onSubmit={(e) => {
                                setIsEditing(false);
                                onSubmitEdit(e, question.id);
                                window.location.reload(); // Refresh forcefully after save 
                            }}
                            style={{ width: '100%', padding: '1rem', boxSizing: 'border-box' }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%' }}>
                                <QuestionInput
                                    rows={10}
                                    style={{
                                        width: '100%',
                                        padding: '1rem',
                                        background: '#2c2c2c',
                                        color: 'white',
                                        border: '1px solid #EC5F40',
                                        borderRadius: '8px',
                                        boxSizing: 'border-box'
                                    }}
                                    name='answer'
                                    defaultValue={question.answer.answer}
                                />
                                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                                    <Button type='submit' style={{ width: '120px' }}>Save</Button>
                                    <Button
                                        type='button'
                                        onClick={() => setIsEditing(false)}
                                        style={{ backgroundColor: '#555', color: 'white', width: '120px' }}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </form>
                    ) : (
                        <div style={{ width: '100%', padding: '1rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid #EC5F40', borderRadius: '8px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                <div style={{ color: '#EC5F40', fontWeight: 'bold' }}>Answered By: {`${question?.answer?.user?.lastName ?? ''} ${question?.answer?.user?.firstName ?? ''}`}</div>
                                <div>{question.answer.answer}</div>
                            </div>
                            {user.role.name === 'Admin' && (
                                <AddAnswerButton onClick={() => setIsEditing(true)}>
                                    <ChatIcon />
                                    Edit answer
                                </AddAnswerButton>
                            )}
                        </div>
                    )}
                </StatusRow>
            ) : (
                <StatusRow>
                    {isAnswering ? (
                        <form
                            onSubmit={(e) => {
                                setIsAnswering(false);
                                onSubmitAnswer(e, question.id);
                            }}
                            style={{ width: '100%', padding: '1rem', boxSizing: 'border-box' }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%' }}>
                                <QuestionInput
                                    rows={10}
                                    style={{
                                        width: '100%',
                                        padding: '1rem',
                                        background: '#2c2c2c',
                                        color: 'white',
                                        border: '1px solid #EC5F40',
                                        borderRadius: '8px',
                                        boxSizing: 'border-box'
                                    }}
                                    name='answer'
                                />
                                <Button type='submit' style={{ width: '120px' }}>Submit Answer</Button>
                            </div>
                        </form>
                    ) : (
                        <div style={{ padding: '1rem', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            <div>Not answered</div>
                            {user.role.name == 'Admin' && (
                                <AddAnswerButton onClick={handleAddAnswer}>
                                    <ChatIcon />
                                    Add answer
                                </AddAnswerButton>
                            )}
                        </div>
                    )}
                </StatusRow>
            )}
        </div>
    )
};


// TODO implement infinite scroll
const QAList = ({ subchapter, nextButton, handleNextClick }) => {
    const { user } = useAuth();
    const [questions, setQuestions] = useState([]);
    const [askQuestionState, setAskQuestionState] = useState(false); // false = button; true = textarea
    const [page, setPage] = useState(1);

    useEffect(() => {
        const fetchQuestions = async () => {
            const response = await API.get(`/questions/${subchapter.id}?page=${page}`);
            setQuestions(response.data.questions);
        }

        if (!questions.length) {
            fetchQuestions();
        }


    }, [questions.length, subchapter]);

    const handleAskQuestion = () => {
        setAskQuestionState(prev => !prev);
    }

    const handleViewMore = async (e) => {
        e.preventDefault();

        const viewMorePromise = new Promise(async (resolve, reject) => {
            try {
                // Fetch additional questions for the next page
                const result = await API.get(`/questions/${subchapter.id}?page=${page + 1}`);

                if (result.status === 200) {
                    resolve(result);
                } else {
                    reject(result);
                }
            } catch (error) {
                reject(error);
            }
        });

        toast.promise(viewMorePromise, {
            loading: 'Fetching more questions...',
            success: (result) => {
                const newQuestions = result.data.questions;

                // Create a set of existing question IDs from the current state
                const existingQuestionIds = new Set(questions.map(question => question.id));

                // Filter new questions to remove any that already exist
                const uniqueNewQuestions = newQuestions.filter(question => !existingQuestionIds.has(question.id));

                // Update the state with unique new questions
                setQuestions(prevQuestions => [...prevQuestions, ...uniqueNewQuestions]);

                // Update the page number for pagination
                setPage(prev => prev + 1);

                return `${uniqueNewQuestions.length ? 'Questions are now displayed!' : 'No more questions left to display!'}`;
            },
            error: (error) => {
                return `Failed to fetch questions: ${error?.response?.data?.message ?? error.message}`;
            }
        });
    };


    const handleDeleteComment = async (questionId) => {
        const deletePromise = new Promise(async (resolve, reject) => {
            try {
                const result = await API.delete(`/questions/${questionId}`);
                if (result.status === 200) {
                    resolve(result);
                } else {
                    reject(result);
                }
            } catch (error) {
                reject(error);
            }
        });

        toast.promise(deletePromise, {
            loading: 'Deleting comment...',
            success: () => {
                // Update the state by filtering out the deleted question
                setQuestions(prevQuestions =>
                    prevQuestions.filter(question => question.id !== questionId)
                );
                return `Comment deleted successfully!`;
            },
            error: (error) => {
                return `Failed to delete question: ${error?.response?.data?.message ?? error.message}`;
            },
        });
    };


    const handleAnswerSubmit = async (e, questionId) => {
        e.preventDefault();

        const answerText = e.target.answer.value;
        if (!answerText || answerText.trim() === '') {
            return toast.warning('Cancelled question submission');
        }

        const data = {
            questionId: questionId,
            userId: user.id,
            answer: answerText
        };

        try {
            const result = await API.post(`/answers`, data);

            if (result.status === 200) {
                // Assuming result.data contains the new answer object
                const newAnswer = result.data;

                // Update the questions state array
                setQuestions(prevQuestions =>
                    prevQuestions.map(question =>
                        question.id === questionId
                            ? { ...question, answer: newAnswer || null }
                            : question
                    )
                );

                return toast.success('Answer submitted successfully');
            } else {
                return toast.error('Something went wrong');
            }
        } catch (error) {
            return toast.error('An error occurred');
        }
    };


    const handleQuestionSubmit = async (e) => {
        e.preventDefault();
        setAskQuestionState(false);
        if (!e?.target?.question?.value || e.target.question.value === '') {
            return toast.warning('Cancelled question submission');
        }
        const data = {
            question: e.target.question.value,
            subchapterId: subchapter.id,
            userId: user.id,
        }
        const result = await API.post('/questions', data);
        if (result.status == 200) {
            setQuestions([result.data, ...questions]);
            toast.success('Question submitted with success!');
        } else {
            toast.error('Error submitting question');
        }
    }

    return (
        <TestWrapper>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1rem' }}>
                <AskQuestionButton onClick={handleAskQuestion}><ChatIcon />Ask a question</AskQuestionButton>
                {nextButton() !== false && (
                    <NextButton onClick={() => handleNextClick('next')} style={{ display: 'flex', paddingLeft: '2rem', paddingRight: '2rem', paddingTop: '0.5rem', paddingBottom: '0.5rem', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
                        <span>Next </span>
                        <MdSkipNext size={30} />
                    </NextButton>
                ) || (
                        <NextButton onClick={() => handleNextClick('finish')} style={{ display: 'flex', paddingLeft: '2rem', paddingRight: '2rem', paddingTop: '0.5rem', paddingBottom: '0.5rem', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
                            <span>Finish </span>
                            <MdSkipNext size={30} />
                        </NextButton>
                    )}
            </div>
            {askQuestionState && (
                <form onSubmit={handleQuestionSubmit}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        <QuestionInput rows={10} name='question' />
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button onClick={(e) => handleQuestionSubmit(e)}>Cancel</Button>
                            <Button type='submit'>Submit Question</Button>
                        </div>
                    </div>
                </form>
            )}
            {questions &&
                questions.map(item => (
                    <QAItem
                        key={item.id}
                        question={item}
                        onSubmitAnswer={handleAnswerSubmit}
                        onDelete={handleDeleteComment}
                    />
                ))
            }
            <ViewMoreWrapper>
                <Button onClick={(e) => handleViewMore(e)}>Show More</Button>
            </ViewMoreWrapper>
        </TestWrapper>
    )
};

export default QAList;
