import React, { useState, useEffect } from 'react';
import { useSettings } from '../../providers/SettingsProvider';
import { ControlPanelContainer, SaveButton } from '../../components/common/styledComponents/controlPanelStyles';
import Autoplay from './ControlPanelSections/Autoplay'
import SocialMediaLinks from './ControlPanelSections/SocialMediaLinks';
import API from '../../api/axios';
import { toast } from 'sonner';
import Metadata from './ControlPanelSections/Metadata';

const ControlPanel = () => {
    const { settings } = useSettings();
    const [systemSettings, setSystemSettings] = useState({
        enable_auto_course_progression: {
            value: false,
            type: 'boolean',
            description: 'Enable or disable automatic course progression.',
        },
        enable_autoplay: {
            value: false,
            type: 'boolean',
            description: 'Enable or disable autoplay.',
        },
        social_media_links: {
            value: null,
            type: 'json',
            description: 'Social media links.',
        },
        metadata: {
            value: null,
            type: 'json',
            description: 'Metadata settings for the site.',
        },
    });

    useEffect(() => {
        if (settings) {
            const updatedSettings = Object.keys(systemSettings).reduce((acc, key) => {
                const matchingSetting = settings.find(s => s.key === key);
                if (matchingSetting) {
                    if (systemSettings[key].type === 'json') {
                        acc[key] = { ...systemSettings[key], value: JSON.parse(matchingSetting.value) };
                    } else if (systemSettings[key].type === 'boolean') {
                        acc[key] = { ...systemSettings[key], value: matchingSetting.value === 'true' };
                    } else {
                        acc[key] = { ...systemSettings[key], value: matchingSetting.value };
                    }
                } else {
                    acc[key] = systemSettings[key];
                }
                return acc;
            }, {});
            setSystemSettings(updatedSettings);
        }
    }, [settings]);


    const handleSaveSettings = async (state) => {
        try {
            const settingsPayload = Object.keys(state).reduce((acc, key) => {
                const setting = state[key];
                if (setting.value !== null && setting.value !== undefined) {
                    acc.push({
                        key,
                        value: setting.type == 'json' ? JSON.stringify(setting.value) : setting.value.toString(),
                        type: setting.type,
                        description: setting.description,
                    });
                }
                return acc;
            }, []);

            if (settingsPayload.length > 0) {
                const response = await API.post('/system-settings', settingsPayload);

                if (response.data) {
                    window.dispatchEvent(new Event('settingsUpdated'));
                    toast.success('Settings saved successfully!');
                } else {
                    toast.error('Unexpected response format.');
                }
            } else {
                toast.info('No changes to save.');
            }
        } catch (error) {
            console.error("Error:", error); // Loghează eroarea completă
            toast.error('Error saving settings.');
        }
    };

    const handleManualProgressionToggle = () => {
        setSystemSettings({
            ...systemSettings,
            enable_auto_course_progression: {
                ...systemSettings.enable_auto_course_progression,
                value: !systemSettings.enable_auto_course_progression.value,
            },
        });
    };

    const handleAutoplayToggle = () => {
        setSystemSettings({
            ...systemSettings,
            enable_autoplay: {
                ...systemSettings.enable_autoplay,
                value: !systemSettings.enable_autoplay.value,
            },
        });
    };

    const handleSocialMediaChange = (event, index, platform) => {
        // Clone the current social media links array
        let socialMediaLinks = systemSettings.social_media_links.value || [];

        // Update the relevant link or remove it if the value is empty
        if (event.target.value.trim() === '') {
            // Remove the link if the new value is empty
            socialMediaLinks = socialMediaLinks.filter(link => link.platform !== platform);
        } else {
            // Update or add the link
            const existingLink = socialMediaLinks.find(link => link.platform === platform);
            if (existingLink) {
                existingLink.value = event.target.value;
            } else {
                socialMediaLinks.push({ platform, value: event.target.value });
            }
        }

        // Update the systemSettings state
        setSystemSettings({
            ...systemSettings,
            social_media_links: {
                ...systemSettings.social_media_links,
                value: socialMediaLinks,
            },
        });
    };

    const handleMetadataChange = (event, key) => {
        // Clone the current metadata object
        let metadata = systemSettings.metadata.value && systemSettings.metadata.value != '' ? { ...systemSettings.metadata.value } : {};

        // Update the relevant key in the metadata object
        metadata[key] = event.target.value;

        // Update the relevant key in the metadata object
        if (event.target.value.trim() === '') {
            // Remove the key if the new value is empty
            delete metadata[key];
        } else {
            // Otherwise, set or update the key's value
            metadata[key] = event.target.value;
        }


        // Update the systemSettings state
        setSystemSettings({
            ...systemSettings,
            metadata: {
                ...systemSettings.metadata,
                value: metadata,
            },
        });
    };


    return (
        <ControlPanelContainer>
            <h1>Control Panel</h1>
            <h2>Metadata</h2>
            <Metadata
                metadata={systemSettings.metadata.value}
                handleMetadataChange={handleMetadataChange}
            />
            <h2>Social Media</h2>
            <SocialMediaLinks
                socialMediaLinks={systemSettings.social_media_links.value}
                handleSocialMediaChange={handleSocialMediaChange}
            />
            <h2>Autoplay</h2>
            <Autoplay
                isEnabledManualProgression={systemSettings.enable_auto_course_progression.value}
                isEnabledAutoplay={systemSettings.enable_autoplay.value}
                handleManualProgressionToggle={handleManualProgressionToggle}
                handleAutoplayToggle={handleAutoplayToggle}
            />
            <SaveButton onClick={() => handleSaveSettings(systemSettings)}>
                Save Settings
            </SaveButton>
        </ControlPanelContainer>
    );
};

export default ControlPanel;