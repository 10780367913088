import React from 'react';
import styled from 'styled-components';

const CircleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`;

const Circle = styled.svg`
    transform: rotate(-90deg);
`;

const CirclePath = styled.circle`
    fill: none;
    stroke-width: ${props => props.strokeWidth}px;
    
`;

const ProgressPath = styled.circle`
    fill: none;
    stroke-width: ${props => props.strokeWidth}px;
    stroke-linecap: round;
    stroke: #EC5F40;
    transition: stroke-dashoffset 0.3s ease;
`;

const Percentage = styled.div`
    position: absolute;
    font-size: ${props => props.fontSize};
    font-weight: bold;
`;

const CircleProgress = ({ percentage, size = 120, strokeWidth = 10, fontSize = '1rem' }) => {
    const radius = (size - strokeWidth) / 2;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - (percentage / 100) * circumference;

    return (
        <CircleWrapper>
            <Circle width={size} height={size}>
                <CirclePath
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    strokeWidth={strokeWidth}
                />
                <ProgressPath
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    style={{ strokeDasharray: circumference, strokeDashoffset: offset }}
                    strokeWidth={strokeWidth}
                />
            </Circle>
            <Percentage fontSize={fontSize}>
                {percentage}%
            </Percentage>
        </CircleWrapper>
    );
};

export default CircleProgress;
