// src/api/axios.js
import axios from 'axios';

const API = axios.create({
    baseURL: process.env.REACT_APP_API_URL, // Adjust this URL to match your server's address
    headers: {
        'Content-Type': 'application/json'
    },
    withCredentials: true,
});

export default API;
