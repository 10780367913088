import styled from 'styled-components';

export const QAContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin-top: 2rem;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    font-size: 0.75rem;
    padding-top: 0.5rem; /* Adjust padding to account for fixed header */
    @media (max-width: 1024px) {
        padding-top: 1rem; /* Extra padding for smaller screens */
    }
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    width: 25rem;
    justify-content: space-between;
    @media (max-width: 1024px) {
        width: 100%;
        justify-content: space-between; /* Center the content on smaller screens */
        gap: 0.5rem; /* Reduced gap for smaller screens */
        font-size: 0.65rem; /* Reduced font size for smaller screens */
    }
`;

export const SidebarWrapper = styled.div`
    @media (max-width: 1024px) {
        display: none; /* Hide sidebar on smaller screens */
    }
`;

export const Button = styled.button`
    cursor: pointer;
    width: fit-content;
    padding: 1rem;
    font-size: 0.8rem;
    font-weight: 600;
    color: white;
    background: #EC5F40;
    border: 0px;
    border-radius: 8px;
    margin-bottom: 1rem;

    &:hover {
        background: #DA5A50;
    }
`;

export const MainActionButtonsWrapper = styled.div`
    display: flex;
    gap: 1rem;
    margin-left: auto;
    @media (max-width: 1024px) {
        display: none;
    }
`;