import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSettings } from '../../providers/SettingsProvider';

const HeadMetadata = () => {
    const { settings } = useSettings();
    const [metadata, setMetadata] = useState({
        title: 'MakeSoftwares - Your go-to platform for software solutions and updates',
        description: 'MakeSoftwares offers a range of software solutions and updates to meet your needs.',
        ogTitle: 'MakeSoftwares',
        ogDescription: 'MakeSoftwares offers a range of software solutions and updates to meet your needs.',
        ogImage: `${process.env.REACT_APP_URL}/og_image.png`,
        ogUrl: 'https://curs.makesoftwares.com',
        twitterTitle: 'MakeSoftwares',
        twitterDescription: 'MakeSoftwares offers a range of software solutions and updates to meet your needs.',
        twitterImage: `${process.env.REACT_APP_URL}/og_image.png`,
    });

    useEffect(() => {
        if (settings) {
            const metadataSetting = settings.find(s => s.key === 'metadata');
            if (metadataSetting && metadataSetting.value) {
                const parsedMetadata = JSON.parse(metadataSetting.value);
                setMetadata(prevMetadata => ({
                    ...prevMetadata,
                    ...parsedMetadata,
                }));
            }
        }
    }, [settings]);

    return (
        <Helmet>
            <title>{metadata.title}</title>
            <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap" rel="stylesheet" />
            <meta name="description" content={metadata.description} />
            <meta property="og:title" content={metadata.ogTitle} />
            <meta property="og:description" content={metadata.ogDescription} />
            <meta property="og:image" content={metadata.ogImage} />
            <meta property="og:url" content={metadata.ogUrl} />
            <meta property="og:type" content="website" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@your_twitter_handle" />
            <meta name="twitter:title" content={metadata.twitterTitle} />
            <meta name="twitter:description" content={metadata.twitterDescription} />
            <meta name="twitter:image" content={metadata.twitterImage} />
        </Helmet>
    );
};

export default HeadMetadata;
