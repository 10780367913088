import styled from "styled-components";
import { Container } from "../components/common/styles";
import Input from "../components/common/Input";
// import { FcGoogle } from "react-icons/fc";
import { registerUser } from "../api/users";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const Card = styled.div`
  width: 25rem;
  display: flex;
  flex-direction: column;
  padding: 5rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 0;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    inset: 0;
    padding: 1px;
    border-radius: 15px;
    background: linear-gradient(180deg, #9F9B9B 0%, #666666 100%);
    -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
            mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
    }

  @media (max-width: 600px) {
    width:100%;
    padding: 1.5rem; /* Adjust padding for smaller screens */
    border-radius: 0.25rem; /* Adjust border radius */
  }
`;

const CardHeader = styled.div`
  width: 100%;
  padding: 10px 0;
  margin-bottom: 3rem;
  font-size: 2rem;
  font-weight: bold;
`;

const CardBody = styled.div`
  width: 100%;
`;

const CardFooter = styled.div`
  width: 100%;
  border-top: 1px solid #ddd;
`;

const CenterContainer = styled.div`
  font-family: Poppins;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

const Button = styled.button`
    cursor: pointer;
    width: 100%;
    padding: 1.3rem;
    font-size: 1.3rem;
    font-weight: 600;
    color: white;
    background: #EC5F40;
    border: 0px;
    border-radius: 8px;
    margin-bottom: 1rem;

    &:hover {
        background: #DA5A50;
    }
`

const CustomWord = styled.span`
background: linear-gradient(90.93deg, #EC5F40 0.8%, #999999 63.15%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display:block;
`

const NextButton = styled.button`
    cursor: pointer;
    background: inherit;
    color: white;
    padding: 0.5rem 2rem 0.5rem 2rem;
    border:1px solid #EC5F40;
    border-radius: 8px;
    margin-top: 1rem;
`

const Copyright = styled.div`
  font-size: 0.9rem;
  color: #9F9B9B;
  margin-top: 0.2rem;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(90deg, #EC5F40, #999999);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
`;

// const GoogleButton = styled.button`
//     cursor: pointer;
//     display:flex;
//     flex-direction:row;
//     justify-content:center;
//     align-items:center;
//     gap: 0.3rem;
//     width: 100%;
//     padding: 1.3rem;
//     font-size: 1.3rem;
//     font-weight: 600;
//     color: white;
//     background: #9F9B9B;
//     border: 0px;
//     border-radius: 8px;
//     text-align:center;

//     &:hover {
//         background: #908D8D;
//     }
// `

const Register = () => {
    const navigate = useNavigate();
    const [isSuccessfulRegistration, setIsSuccessfulRegistration] = useState(false);

    const handleRegistration = async (e) => {
        e.preventDefault();
        const { email, password, firstName, lastName, termsAccepted } = e.target
        const data = {
            email: email.value,
            password: password.value,
            firstName: firstName.value,
            lastName: lastName.value,
            termsAccepted: termsAccepted.value
        };
        // TODO move this to promise and maybe use toast as loading state
        const result = await registerUser(data);
        if (result.status === 200) {
            setIsSuccessfulRegistration(true);
        } else {
            alert(result.data.message);
        }
    }

    return (
        <Container>
            <CenterContainer>
                {isSuccessfulRegistration ? (
                    <>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <h1 style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap', margin: 0, fontWeight: 600, fontSize: '3rem', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
                                <CustomWord>
                                    SUCCESSFULLY
                                </CustomWord>
                                <span style={{ display: 'block' }}>
                                    REGISTERED
                                </span>
                            </h1>
                            <p style={{ color: '#9F9B9B', margin: 0, fontSize: '1rem', textAlign: 'center' }}>
                                Congratulation! Your Account is Registered
                                in this application
                            </p>
                            <NextButton onClick={() => navigate('/login')}>Next</NextButton>
                        </div>
                    </>
                ) :
                    (
                        <Card>
                            <CardHeader>
                                Create an account
                            </CardHeader>
                            <CardBody>
                                <form onSubmit={handleRegistration}>
                                    <Input name="email" type="email" placeholder="user@example.com" label={"Email"} required />
                                    <Input name="firstName" type="text" placeholder="eg John" label={"First name"} required />
                                    <Input name="lastName" type="text" placeholder="eg Smith" label={"Last name"} required />
                                    <Input name="password" type="password" placeholder="********" label={"Password"} required />
                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                                        <input type="checkbox" name="termsAccepted" required />
                                        <span style={{ marginLeft: '0.5rem', color: 'white' }}>I accept the Terms and Conditions</span>
                                    </div>
                                    <Button type="submit" >Create account</Button>
                                </form>
                                {/* <GoogleButton onClick={() => alert('test')}>
                            <FcGoogle />
                            <span>Continue with Google</span>
                        </GoogleButton>
                         */}
                                <span style={{ textAlign: 'center', width: '100%', display: 'block', marginTop: '1.5rem', color: '#9F9B9B' }}>Already have an account? <a href="/login" style={{ color: '#EC5F4099', textDecoration: 'none' }}>Log in</a></span>
                            </CardBody>
                            <CardFooter>
                                <Copyright>© 2024 makesoftwares.com</Copyright>
                            </CardFooter>
                        </Card>
                    )
                }
            </CenterContainer>
        </Container>
    )
}

export default Register;