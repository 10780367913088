import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  color: white;
  width:100%;
  height:100%;
    @media (max-width: 1024px) {
    padding: 0;
  }
`;

export const Sidebar = styled.div`
  width: 400px;
  margin-right: 20px;
`;

export const Section = styled.div`
  margin-bottom: 20px;
  border: 1px solid #ff4c4c;
  border-radius: 8px;
  // overflow: hidden;
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1e1e1e;
  padding: 10px 15px;
  border-bottom: 1px solid #333;
`;

export const SectionContent = styled.div`
  background-color: #1e1e1e;
  padding: 10px 0;
`;

export const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid #333;
  cursor: pointer;
  background-color: ${props => props.active ? '#555' : 'transparent'}; /* Apply active style based on prop */
  transition: 0.2s;
  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: ${props => props.active ? '#555' : '#333'};
  }

  &:active {
    background-color: #555;
  }
`;

export const PlayIcon = styled.div`
  width: 16px;
  height: 16px;
  background-color: white;
  mask: url('path-to-play-icon.svg') no-repeat center / contain;
  margin-right: 10px;
`;

export const ItemText = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

export const TimeStamp = styled.div`
  color: #999;
  margin-left: 10px;
`;

export const TimestampCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckBox = styled.input.attrs({ type: 'checkbox' })`
  width: 16px;
  height: 16px;
  margin-left: 10px;
  appearance: none;
  background-color: black;
  border: 1px solid #ff4c4c;
  border-radius: 3px;
  cursor: pointer;

  &:checked {
    background-color: #ff4c4c;
  }

  &:focus {
    outline: none;
  }
`;

export const VideoContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;



export const QnASection = styled.div`
  background-color: #1e1e1e;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #333;
`;

export const QnAHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid #333;
  padding-bottom: 10px;
`;

export const QnAContent = styled.div`
  margin-bottom: 20px;
`;

export const QuestionInput = styled.textarea`
  background-color: #333;
  color: white;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
`;

export const AskButton = styled.button`
  background-color: #ff4c4c;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
`;

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
`;

export const CourseTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

export const AutoplayToggle = styled.div`
  display: flex;
  align-items: center;
`;

export const ToggleLabel = styled.div`
  margin-right: 10px;
`;

export const ToggleSwitch = styled.div`
  width: 40px;
  height: 20px;
  background-color: ${props => (props.on ? '#ff4c4c' : '#555')};
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;

  &:before {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    background-color: white;
    border-radius: 50%;
    top: 1px;
    left: ${props => (props.on ? 'calc(100% - 19px)' : '1px')};
    transition: left 0.3s;
  }
`;
