// Layout.js
import React from 'react';

import styled from 'styled-components';
import { Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../features/AuthContext';
import AdminSidebar from './AdminSidebar';

const LayoutContainer = styled.div`
  display: flex;
  background-color: #121212;
  font-family: Poppins;
`;

const ContentArea = styled.div`
  flex: 1;
`;

const Layout = () => {
  const { user } = useAuth();
  const currentLocation = useLocation();
  return (
    <LayoutContainer>
      {user && user?.role?.name == 'Admin' && !currentLocation.pathname.includes('/course/') && (
        <AdminSidebar />
      )}
      <ContentArea>
        <Outlet />
      </ContentArea>
    </LayoutContainer>
  );
};

export default Layout;