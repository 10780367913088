import React, { useState, useEffect } from 'react';
import { AiFillTikTok } from "react-icons/ai";
import { FaFacebookSquare, FaInstagram, FaYoutube } from "react-icons/fa";
import { useSettings } from '../providers/SettingsProvider';


const SocialMediaButtons = () => {
    const { settings } = useSettings();
    const [socialMediaLinks, setSocialMediaLinks] = useState({
        YouTube: "https://www.youtube.com/@akos.makesoftwares",
        Facebook: "https://www.facebook.com/profile.php?id=61557778268307",
        TikTok: "https://www.tiktok.com/@akos.makesoftwares",
        Instagram: "https://www.instagram.com/akos.makesoftwares/",
    });

    useEffect(() => {
        if (settings) {
            const socialMediaSetting = settings.find(s => s.key === 'social_media_links');
            if (socialMediaSetting && socialMediaSetting.value) {
                const parsedLinks = JSON.parse(socialMediaSetting.value);
                const updatedLinks = parsedLinks.reduce((acc, link) => {
                    acc[link.platform] = link.value;
                    return acc;
                }, { ...socialMediaLinks }); // Start with defaults
                setSocialMediaLinks(updatedLinks);
            }
        }
    }, [settings]);

    return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '0.4rem', marginTop: '1rem' }}>
            <a href={socialMediaLinks.YouTube} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                <FaYoutube color="red" size={40} />
            </a>
            <a href={socialMediaLinks.Facebook} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                <FaFacebookSquare color="#4267B2" size={40} />
            </a>
            <a href={socialMediaLinks.TikTok} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                <AiFillTikTok size={40} />
            </a>
            <a href={socialMediaLinks.Instagram} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                <FaInstagram color="#e1306c" size={40} />
            </a>
        </div>
    );
};

export default SocialMediaButtons;