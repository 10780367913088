import React from 'react';
import styled from 'styled-components';

const Input = styled.input`
  padding: 0.4rem;
  width: 60%;
  border: 1px solid #444;
  border-radius: 4px;
  background: #222;
  color: white;

  &:focus {
    border-color: #EC5F40;
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.4rem;
`;

const Metadata = ({ metadata, handleMetadataChange }) => {
  const metadataFields = [
    { key: 'title', label: 'Title' },
    { key: 'description', label: 'Description' },
    { key: 'ogTitle', label: 'OG Title' },
    { key: 'ogDescription', label: 'OG Description' },
    { key: 'ogUrl', label: 'OG URL' },
    { key: 'twitterTitle', label: 'Twitter Title' },
    { key: 'twitterDescription', label: 'Twitter Description' },
  ];

  return (
    <>
      {metadataFields.map((field, index) => (
        <div key={index}>
          <Label>{field.label}:</Label>
          <Input
            type="text"
            defaultValue={metadata?.[field.key] || ''}
            placeholder={`Enter ${field.label.toLowerCase()}`}
            onChange={(e) => handleMetadataChange(e, field.key)}
          />
        </div>
      ))}
    </>
  );
};

export default Metadata;
