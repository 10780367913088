import styled from "styled-components";

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem; /* Add some spacing between inputs */
`;

const InputStyle = styled.input`
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 16px;
  padding: 1rem;
  border: double 3px transparent;
  background-image: linear-gradient(#121212, #121212), 
                    linear-gradient(90deg, #EC5F40 0%, #666666 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  color: white;
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
`;

const LabelStyle = styled.label`
  font-size: 16px;
  margin-bottom: 0.5rem; /* Add some spacing between label and input */
  color: white; /* Ensure label text is visible on dark background */
`;

const Input = ({ label, ...props }) => {
  return (
    <InputContainer>
      {label && <LabelStyle>{label}</LabelStyle>}
      <InputStyle {...props} />
    </InputContainer>
  );
};

export default Input;
